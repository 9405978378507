import { React, Component, useState, useEffect, useRef, createContext, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getCurrentUser, getIsAreasLoading } from "../../store/selectors";
import { changeBeingModeForDevice, getAreasList, getBlockedDevicePropertiesList, getDevicePropertiesList, getTotalHashrateHistory, pingDevice, rebootDevice, updateDeviceField } from '../../store/actions';
import Swal from 'sweetalert2';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import useWebSocket, { ReadyState } from "react-use-websocket"
import { saveAs } from 'file-saver';

import {
    MRT_ActionMenuItem,
  MRT_SelectCheckbox,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { hashrateTransformation } from '../../utils/hashrate';
import { ContentCopy, Delete, Edit, Email, PersonOffOutlined } from '@mui/icons-material';
import { Box, Button, Modal, MenuItem, Typography, CircularProgress, DialogActions } from '@mui/material';
import { color } from 'framer-motion';
import { WS_URL, API_URL } from '../../config/config';
import { accessName } from '../../utils/access';
import DeviceHashrateHistory from './DeviceHH';
import DeviceAsicPanel from './DeviceAsicPanel';
import ThumbIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { approveDeviceRequest, getAsicLogs, getWorkersPropertiesList, rejectDeviceRequest } from '../../store/actions/device';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import DeviceProvider from '../../api/DeviceProvider';
import DeviceLogViewer from './DeviceLogViewer';

const formatTime = (value) => {
    var
    d = Math.trunc(value / 60    / 60   / 24).toString().padStart(2, '0'),
    h = Math.trunc(value % 86400 / 60   / 60).toString().padStart(2, '0'),
    m = Math.trunc(value % 86400 % 3600 / 60).toString().padStart(2, '0'),
    s = Math.trunc(value % 86400 % 3600 % 60).toString().padStart(2, '0');

    return (d > 0) ? d + ' days ' + h + ':' + m + ':' + s : h + ':' + m + ':' + s
}

const formatPoolUrls = (value) => {
    if(value == null)   {
        return value
    }
    let ret = value.replace('stratum+tcp://', '')
    ret = ret.replace('stratum+tcp://', '')
    ret = ret.replace('stratum+tcp://', '')
    ret = ret.replace('stratum+tcp://stratum+ssl://', '')
    ret = ret.replace('stratum+tcp://stratum+ssl://', '')
    ret = ret.replace('stratum+tcp://stratum+ssl://', '')
    // return ret.replace('/stratum+tcp://stratum+ssl:///g', '')
    return ret
}

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

export const DeviceContext = createContext('');

export default function DevicesDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => getCurrentUser(state))
    const [_isOwnerUser, setIsOwnerUser] = useState(accessName(currentUser.access_type) == 'OWNER')
    const [_isAdminUser, setIsAdminUser] = useState(accessName(currentUser.access_type) == 'ADMIN')
    const [_isTechUser, setIsTechUser] = useState(accessName(currentUser.access_type) == 'TECH')
    const [_isFinanceUser, setIsFinanceUser] = useState(false) 
    //accessName(currentUser.access_type) == 'FINANCE'
    
    const isFirstRender = useRef(true);

    //optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef(null);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);

    const [areas, setAreas] = useState([]);
    const [areaIndex, setAreaIndex] = useState(0);
    const isAreaLoading = useSelector(state => getIsAreasLoading(state));
    const [showBlockedDevices, setShowBlockedDevices] = useState(0);

    const [muiTableKey, setMuiTableKey] = useState(0);

    const [deviceProperties, setDeviceProperties] = useState([]);
    const [isDevicePropertiesLoading, setIsDevicePropertiesLoading] = useState(false);
    const [devicePropertiesSource, setDevicePropertiesSource] = useState([]);
    const [warningColumns, setWarningColumns] = useState([]);

    const [workersProperties, setWorkersProperties] = useState(false);
    const [isWorkersPropertiesLoading, setIsWorkersPropertiesLoading] = useState(false);

    const [isBlockedDevicePropertiesLoading, setIsBlockedDevicePropertiesLoading] = useState(false);
    const [blockedDevicePropertiesSource, setBlockedDevicePropertiesSource] = useState([]);

    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedDeviceForAsicPanel, setSelectedDeviceForAsicPanel] = useState(null);

    const [openLogView, setOpenLogView] = useState(false);
    const [logData, setLogData] = useState('');
    const [loadingLogData, setLoadingLogData] = useState(false);
    const [asicIPForLog, setAsicIPForLog] = useState('');

    const cell_normal_style = {fontWeight:'normal', color:'black'}
    const cell_warning_style = {fontWeight:'bold', color:'red'}

    const CustomCell = (cell, row, name) => {
        const isWarningCell = row.original['meta'].indexOf(name)
        return (
            <span style={(isWarningCell > -1) ? cell_warning_style : cell_normal_style}>{`${cell.getValue()}`}</span>
        )
    }

    const [validationErrors, setValidationErrors] = useState({});
    const validateRequired = (value) => !!value.length;

    const _devicePropertyListRequest_AbortController = useRef(null)
    const _workersPropertyListRequest_AbortController = useRef(null)
    const _deviceBlockedListRequest_AbortController = useRef(null)

    const [openLogViewer, setOpenLogViewer] = useState({
        open: false,
        area_uuid: '',
        device_ip: '',
    });
    const handleOpenLogViewer = (device_ip) => {
        setOpenLogViewer({
            open: true,
            area_uuid: areas[areaIndex].id,
            device_ip: device_ip,
        })
    }
    const handleCloseLogViewer = () => {
        setOpenLogViewer({
            open: false,
            area_uuid: '',
            device_ip: '',
        })
    }

    console.log(WS_URL + `/${currentUser.id}/ws`)
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL + `/api/v1/${currentUser.id}/ws`,
        {
            share: false, 
            retryOnError: true,
            reconnectAttempts: Infinity,
            reconnectInterval: 5000,
            shouldReconnect: () => true,
        },
    )

    useEffect(() => {
        console.log(`Connection state changed to ${readyState}`)
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: "subscribe",
                data: {
                    channel: "general-chatroom",
                },
            })
        }
    }, [readyState])

    useEffect(() => {
        console.log(`Got a new message: ${JSON.stringify(lastJsonMessage)}`)
        try {
            const ws_message_object = JSON.parse(JSON.stringify(lastJsonMessage))
            const callback_type = ws_message_object['type']
            
            const areaNameIndex = {
                'monitoring': 'Берёзка',
                'shuma': 'Шума',
                'utes': 'Утёс',
                'samara': 'Самара',
                'troick': 'Троицк'
            }
            if(callback_type == 'CALLBACK_PUSH_BUTTON' && showBlockedDevices === 0) {
                const hostname = ws_message_object['hostname']
                const device_ip = ws_message_object['meta']['ip']
                const device_mac = ws_message_object['meta']['mac']

                const fetchAreaName = areaNameIndex[hostname]
                console.log('hostname '+hostname+' == '+ fetchAreaName)
                if(fetchAreaName != areas[areaIndex].name)
                    return

                const text = `<ul><li>Площадка: ${hostname}</li> <li>IP устройства: ${device_ip}</li> <li>MAC устройства: ${device_mac}</li></ul>`
                
                Swal.fire({
                    html: `${text}`,
                });
            }
        } catch (err) {
            console.log(err)
        }
    }, [lastJsonMessage])

    const columns = useMemo(()=>[
        {
            id: 'location_name',
            header: 'Объект',
            accessorFn: (row) => `${row['location_name']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'location_name'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'ip',
            header: 'IP',
            accessorFn: (row) => `${row['ip']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'ip'),
            filterVariant: 'text',
            enableClickToCopy: true,
            enableEditing: false,
            // muiCopyButtonProps: {
            //     fullWidth: true,
            //     startIcon: <ContentCopy />,
            //     sx: { justifyContent: 'flex-start' },
            // },
        },
        {
            id: 'room',
            header: 'Помещение',
            accessorFn: (row) => (row['room'] == null) ? `unknown` : `${row['room']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'room'),
            filterVariant: 'text',
            // muiEditTextFieldProps: {
            //     required: true,
            //     error: !!validationErrors?.room,
            //     helperText: validationErrors?.room,
            //     //remove any previous validation errors when user focuses on the input
            //     onFocus: () =>
            //         setValidationErrors({
            //             ...validationErrors,
            //             room: undefined,
            //         }),
            //     //optionally add validation checking for onBlur or onChange
            // },
        },
        {
            id: 'line',
            header: 'Линия',
            accessorFn: (row) => (row['line'] == null) ? `unknown` : `${row['line']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'line'),
            filterVariant: 'text'
        },
        {
            id: 'place',
            header: 'Место',
            accessorFn: (row) => (row['place'] == null) ? `unknown` : `${row['place']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'place'),
            filterVariant: 'text'
        },
        {
            id: 'model',
            header: 'Модель',
            accessorFn: (row) => `${row['model']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'model'),
            filterVariant: 'multi-select',
            enableClickToCopy: true,
            enableEditing: false,
        },
        {
            id: 'sn',
            header: 'S/N',
            accessorFn: (row) => (row['sn'] == null) ? `unknown` : `${row['sn']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'sn'),
            filterVariant: 'text',
            enableEditing: _isOwnerUser
        },
        {
            id: 'mac',
            header: 'MAC',
            accessorFn: (row) => `${row['mac']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'mac'),
            filterVariant: 'text',
            //enableClickToCopy: true,
            enableEditing: false,
        },
        // {
        //     id: 'worker_edit',
        //     header: 'Worker',
        //     accessorFn: (row) => (row['worker_edit'] == null) ? `unknown` : `${row['worker_edit']}`,
        //     Cell: ({ cell, row }) => CustomCell(cell, row, 'worker_edit'),
        //     filterVariant: 'text',
        //     enableClickToCopy: true,
        // },
        {
            id: 'worker',
            header: 'Имя',
            accessorFn: (row) => `${row['worker']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'worker'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'temperature',
            header: 'Температура',
            accessorFn: (row) => row['temperature'],
            enableEditing: false,
            // Cell: ({ cell, row }) => CustomCell(cell, row, 'temperature'),
            Cell: ({ renderedCellValue, row }) => {
                const isWarningCell = row.original['meta'].indexOf('temperature')
                return (
                    <span style={(isWarningCell > -1) ? cell_warning_style : cell_normal_style}>{renderedCellValue}</span>
                )
            },
            filterVariant: 'range-slider',
            filterFn: 'betweenInclusive', // default (or between),
            muiFilterSliderProps: {
                marks: true,
                step: 1,
                valueLabelFormat: (value) =>
                    value
                },
            },
        {
            id: 'fan_speed',
            header: 'Fan speed',
            accessorFn: (row) => `${row['fan_speed']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'fan_speed'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'hashrate',
            header: 'Hashrate',
            accessorFn: (row) => hashrateTransformation(row['hashrate'], row['rate_unit']),
            Cell: ({ cell, row }) => CustomCell(cell, row, 'hashrate'),
            //Cell: ({ cell }) => `${cell.getValue()} TH/s`,
            columnFilterModeOptions: ['betweenInclusive', 'greaterThan', 'lessThan'],
            filterFn: 'betweenInclusive',
            enableEditing: false,
        },
        {
            id: 'hashrate_ideal',
            header: 'Hashrate ideal',
            accessorFn: (row) => hashrateTransformation(row['hashrate_ideal'], row['rate_unit']),
            Cell: ({ cell, row }) => CustomCell(cell, row, 'hashrate_ideal'),
            //Cell: ({ cell }) => `${cell.getValue()} TH/s`,
            columnFilterModeOptions: ['betweenInclusive', 'greaterThan', 'lessThan'],
            filterFn: 'betweenInclusive',
            enableEditing: false,
        },
        {
            id: 'chain_num',
            header: 'Hash chip count',
            accessorFn: (row) => row['chain_num'],
            Cell: ({ cell, row }) => CustomCell(cell, row, 'chain_num'),
            //Cell: ({ cell }) => `${cell.getValue()}`,
            columnFilterModeOptions: ['betweenInclusive', 'greaterThan', 'lessThan'],
            filterFn: 'betweenInclusive',
            enableEditing: false,
        },
        {
            id: 'is_online',
            header: 'Status',
            accessorFn: (row) => `${row['is_online']}`,
            enableEditing: false,
            Cell: ({ cell }) => cell.getValue() == '1' ? '🟢' : cell.getValue() == '0' ? '🔴' : '⚫️',
            filterVariant: 'select',
            filterSelectOptions: [{'label':'🟢 Online', 'value': '1'}, {'label':'🔴 Offline', 'value': '0'}, {'label':'⚫️ Dead', 'value': '2'}]
        },
        {
            id: 'runtime',
            header: 'Runtime',
            accessorFn: (row) => `${formatTime(row['runtime'])}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'runtime'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'pools_url',
            header: 'Pool/Port',
            accessorFn: (row) => `${formatPoolUrls(row['pools_url'])}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'pools_url'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'pool_rejected',
            header: 'Rejected Shares',
            accessorFn: (row) => `${row['pool_rejected']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'pool_rejected'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'chain_asic',
            header: 'Чипы work:rejected',
            accessorFn: (row) => `${row['chain_asic']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'chain_asic'),
            filterVariant: 'text',
            enableEditing: false,
        },
        {
            id: 'name',
            header: 'Pool name',
            accessorFn: (row) => `${row['name']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'name'),
            filterVariant: 'multi-select',
            enableColumnFilterModes: false,
            enableClickToCopy: true,
            enableEditing: false,
        },
        {
            id: 'compile_time',
            header: 'Прошивка',
            accessorFn: (row) => `${row['compile_time']}`,
            Cell: ({ cell, row }) => CustomCell(cell, row, 'compile_time'),
            grow: true,
            filterVariant: 'text',
            enableClickToCopy: true,
            enableEditing: false,
        },
    ])

    const blockedColumns = useMemo(() => [
        {
            id: 'id',
            header: 'ID',
            accessorFn: (row) => row['id'],
            filterVariant: 'text',
        },
        {
            id: 'address',
            header: 'IP',
            accessorFn: (row) => row['address'],
            filterVariant: 'text',
        },
        {
            id: 'mac',
            header: 'MAC',
            accessorFn: (row) => row['mac_address'],
            filterVariant: 'text',
        },
        {
            id: 'type',
            header: 'type',
            accessorFn: (row) => row['device_type'],
            filterVariant: 'select',
            Cell: ({ cell }) => {
                if(cell.getValue() == 'asic')   {
                    return 'Асик'
                } else if(cell.getValue() == 'net')   {
                    return 'Сетевое оборудование'
                } else if(cell.getValue() == 'device')   {
                    return 'Девайс'
                } else if(cell.getValue() == 'webcam')   {
                    return 'Камера или рег'
                } else if(cell.getValue() == 'untrusted')   {
                    return 'Недоверенное'
                } else  {
                    return 'Неизвестное'
                }
            },
            filterSelectOptions: [
                {'label':'Асики', 'value': 'asic'}, 
                {'label':'Сетевое оборудование', 'value': 'net'}, 
                {'label':'Девайсы / Генераторы', 'value': 'device'},
                {'label':'Камеры и рег', 'value': 'webcam'},
                {'label':'Недоверенные', 'value': 'untrusted'},
                {'label':'Неизвестные', 'value': 'unknown'}
            ]
        }
    ])

    const workerColumns = useMemo(() => [
        {
            id: 'name',
            header: 'name',
            accessorFn: (row) => row['workerName'],
            filterVariant: 'text',
        },
        {
            id: 'hashrate_5m',
            header: 'hashrate_5m',
            accessorFn: (row) => row['hashrate_5m'],
            filterVariant: 'text',
        },
        {
            id: 'hashrate_15m',
            header: 'hashrate_15m',
            accessorFn: (row) => row['hashrate_15m'],
            filterVariant: 'text',
        },
        {
            id: 'stale_15m',
            header: 'stale_15m',
            accessorFn: (row) => row['stale_15m'],
            filterVariant: 'text',
        },
        {
            id: 'stale15mPercent',
            header: 'stale15mPercent',
            accessorFn: (row) => row['stale15mPercent'],
            filterVariant: 'text',
        },
        {
            id: 'reject_15m',
            header: 'reject_15m',
            accessorFn: (row) => row['reject_15m'],
            filterVariant: 'text',
        },
        {
            id: 'reject15mPercent',
            header: 'reject15mPercent',
            accessorFn: (row) => row['reject15mPercent'],
            filterVariant: 'text',
        },
        // {
        //     id: 'rejectDetail_15m',
        //     header: 'rejectDetail_15m',
        //     accessorFn: (row) => row['rejectDetail_15m'],
        //     filterVariant: 'text',
        // },
        {
            id: 'hashrate_1h',
            header: 'hashrate_1h',
            accessorFn: (row) => row['hashrate_1h'],
            filterVariant: 'text',
        },
        {
            id: 'stale_1h',
            header: 'stale_1h',
            accessorFn: (row) => row['stale_1h'],
            filterVariant: 'text',
        },
        {
            id: 'reject_1h',
            header: 'reject_1h',
            accessorFn: (row) => row['reject_1h'],
            filterVariant: 'text',
        },
        // {
        //     id: 'rejectDetail_1h',
        //     header: 'rejectDetail_1h',
        //     accessorFn: (row) => row['rejectDetail_1h'],
        //     filterVariant: 'text',
        // },
        {
            id: 'lastShareTime',
            header: 'lastShareTime',
            accessorFn: (row) => row['lastShareTime'],
            filterVariant: 'text'
        },
        {
            id: 'updatedAt',
            header: 'updatedAt',
            accessorFn: (row) => row['updatedAt'],
            filterVariant: 'text'
        },
        // {
        //     id: 'tag',
        //     header: 'tag',
        //     accessorFn: (row) => row['tag'],
        //     filterVariant: 'text',
        // },
    ])

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(devicePropertiesSource);
        download(csvConfig)(csv);
    };

    const handleExportBlockedDeviceData = () => {
        const csv = generateCsv(csvConfig)(blockedDevicePropertiesSource);
        download(csvConfig)(csv);
    };

    const handleSaveRow = ({
        exitEditingMode,
        row,
        values
    }) => {
        console.log(values)
        // const newValidationErrors = {
        //     room: !validateRequired(values['room']) ? 'Введите название помещения' : '',
        //     line: !validateRequired(values['line']) ? 'Введите номер линии' : '',
        //     sn: !validateRequired(values['sn']) ? 'Введите серийный номер устройства' : '',
        //     place: !validateRequired(values['place']) ? 'Введите название стеллажа, полки и места через точку' : '',
        //     worker_edit: !validateRequired(values['worker_edit']) ? 'Введите название воркера' : '',
        // }
        // if (Object.values(newValidationErrors).some((error) => error)) {
        //         console.log(newValidationErrors)
        //         setValidationErrors(newValidationErrors);
        //         return;
        // }
        // setValidationErrors({});

        // var name = undefined
        // if(devicePropertiesSource[row.index]['room'] !== values['room'])    {
        //     name = 'room'
        // } else if(devicePropertiesSource[row.index]['line'] !== values['line'])    {
        //     name = 'line'
        // } else if(devicePropertiesSource[row.index]['sn'] !== values['sn'])    {
        //     name = 'sn'
        // } else if(devicePropertiesSource[row.index]['place'] !== values['place'])    {
        //     name = 'place'
        // } else if(devicePropertiesSource[row.index]['worker_edit'] !== values['worker_edit'])    {
        //     name = 'worker_edit'
        // }

        // console.log('===>'+name)

        // devicePropertiesSource[row.index] = values;
        // setDevicePropertiesSource([...devicePropertiesSource]);
        // exitEditingMode();
    };

    const handleSaveCell = async (cell, value) => {
        if(value === undefined || value.length == 0)    {
            return
        }

        devicePropertiesSource[cell.row.index][cell.column.id] = value;
        setDevicePropertiesSource([...devicePropertiesSource]);

        const result = await dispatch(updateDeviceField(areas[areaIndex].id, cell.row.getValue('mac'), cell.column.id, value))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }
    };

    const handleLogViewOpen = async (device_ip) => {
        // Имитация загрузки данных
        // setTimeout(() => {
        //     setLogData('Это пример текста логов.\nЗдесь могут быть ошибки или другая информация.');
        //     setLoadingLogData(false);
        // }, 2000);
        setAsicIPForLog(device_ip)
        setOpenLogView(true);
        setLoadingLogData(true);

        const result = await dispatch(getAsicLogs(areas[areaIndex].id, device_ip))
        if(!result.success) {
            setOpenLogView(false);
            return Swal.fire({text:result.message, icon: 'error'});
        }

        const encodedText = result.payload.data['encoded_logs']
        const decodedText = atob(encodedText)
        const plainText = new TextDecoder('utf-8').decode(new Uint8Array([...decodedText].map(char => char.charCodeAt(0))));
        
        setLogData(plainText)
        setLoadingLogData(false);
    };

    const handleLogViewClose = () => setOpenLogView(false);

    const LogModal = ({ open, handleClose, logData, loading }) => {
        const handleSave = () => {
            const blob = new Blob([logData], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `log_${asicIPForLog}.txt`);
        };

        return (
            <Modal
                open={openLogView}
                onClose={handleLogViewClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 900,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb:1}}>
                        Логи {asicIPForLog}
                    </Typography>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Typography id="modal-modal-description" sx={{
                            flex: 1,
                            overflowY: 'auto',
                            whiteSpace: 'pre-wrap',
                        }}>
                            {logData}
                        </Typography>
                    )}
                    <DialogActions sx={{mt:1}}>
                        <Button onClick={handleLogViewClose} color="error" variant="contained">
                            Закрыть
                        </Button>
                        <Button onClick={handleSave} color="primary" variant="contained">
                            Сохранить
                        </Button>
                    </DialogActions>
                </Box>
            </Modal>
        );
    };

    const table = useMaterialReactTable({
        columns: columns,
        data: devicePropertiesSource,
        state: {
            isLoading: isDevicePropertiesLoading,
            showProgressBars: isDevicePropertiesLoading,
            density: 'compact',
            columnVisibility,
            columnOrder,
        },
        // initialState: {
        //     sorting: [
        //         {
        //             id: 'is_online',
        //             desc: true
        //         }
        //     ]
        // },
        enablePagination: false,
        enableRowNumbers: true,
        enableRowVirtualization: true,
        rowVirtualizerInstanceRef,
        rowVirtualizerOptions: { overscan: 10 },
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode:"grid",
        enableColumnDragging: true,
        enableColumnOrdering: true,
        onColumnOrderChange: (setColumnOrder),
        // onDraggingColumnChange: (vvv) => {
        //     console.log(vvv)
        // },

        onColumnVisibilityChange: (setColumnVisibility),
        
        enableRowActions: true,
        renderRowActionMenuItems: ({ closeMenu, row }) => [
            <MenuItem key="reboot" disabled={_isFinanceUser} onClick={() => onRebootDevices([row.getValue('mac')])}>
                Reboot
            </MenuItem>,
            <MenuItem key="ping" disabled={_isFinanceUser} onClick={() => {closeMenu(); onPingDevices([row.getValue('mac')])}}>
                Ping
            </MenuItem>,
            <MenuItem key="normal" disabled={_isFinanceUser} onClick={() => {closeMenu(); onChangeBeingMode([row.getValue('mac')], 'normal')}}>
                Normal
            </MenuItem>,
            <MenuItem key="sleep" disabled={_isFinanceUser} onClick={() => {closeMenu(); onChangeBeingMode([row.getValue('mac')], 'sleep')}}>
                Sleep
            </MenuItem>,
            <MenuItem key="low_power" disabled={_isFinanceUser} onClick={() => {closeMenu(); onChangeBeingMode([row.getValue('mac')], 'low_power')}}>
                Low power
            </MenuItem>,
            <MenuItem key="hashrate_history" onClick={() => onShowHashrateHistory(row.getValue('mac'))}>
                Hashrate
            </MenuItem>,
            <MenuItem key="asic_panel" onClick={() => onShowDevicePanel(row.getValue('mac'))}>
                ASIC Panel
            </MenuItem>,
            <MenuItem key="reject_device" onClick={() => {closeMenu(); onRejectDevice(row.getValue('ip'))}}>
                Reject device
            </MenuItem>,
            <MenuItem key="device_logs" onClick={() => {closeMenu(); handleOpenLogViewer(row.getValue('ip'))}}>
                View Logs
            </MenuItem>,
        ],

        enableRowSelection: (row) => {
            return true //(row.getValue('is_online') === '1' || row.getValue('is_online') === '0')
        },
        enableSelectAll: true,
        enableBatchRowSelection: true,
        //positionToolbarAlertBanner: 'top',
        muiSelectCheckboxProps: { color: 'primary' },

        //memoMode:'cells',

        enableFilterMatchHighlighting: true,

        // muiToolbarAlertBannerProps: {
        //     color: 'error',
        //     children: 'Error loading data',
        // },

        renderToolbarAlertBannerContent: ({ selectedAlert, table }) => (
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%'
            }}>
                <Box sx={{
                    alignItems: 'left',
                    display: 'flex',
                    gap: '6px',
                    p: '4px 12px'
                }}>
                    <MRT_SelectCheckbox table={table} /> {selectedAlert}{' '}
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'left',
                    p: '4px 12px'
                }}>
                    <button className="button is-small is-danger mt-1" style={{width:'100%'}} disabled={_isFinanceUser} onClick={()=>{onRebootDevices(getSelectedMacsFromTable(table))}}>Reboot</button>
                    <button className="button is-small is-info mt-1" style={{width:'100%'}} disabled={_isFinanceUser} onClick={()=>{onPingDevices(getSelectedMacsFromTable(table))}}>Ping</button>
                    <button className="button is-small is-success mt-1" style={{width:'100%'}} disabled={_isFinanceUser} onClick={()=>{onChangeBeingMode(getSelectedMacsFromTable(table), 'normal')}}>Normal</button>
                    <button className="button is-small is-warning mt-1 mb-2" style={{width:'100%'}} disabled={_isFinanceUser} onClick={()=>{onChangeBeingMode(getSelectedMacsFromTable(table), 'sleep')}}>Sleep</button>
                    <button className="button is-small is-warning mt-1 mb-2" style={{width:'100%'}} disabled={_isFinanceUser} onClick={()=>{onChangeBeingMode(getSelectedMacsFromTable(table), 'low_power')}}>Low power</button>
                </Box>
            </Box>
        ),

        renderTopToolbarCustomActions: ({table}) => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        padding: '8px',
                        flexWrap: 'wrap',
                        flexDirection:'row',
                        justifyContent:'space-between'
                    }}>
                    <Typography variant="h6">Выборка устройств: {table.getRowCount()}</Typography>
                    <Button
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={
                            <FileDownloadIcon />
                        }>
                        Export All Data
                    </Button>
                </Box>)
        },

        muiTableHeadCellProps: ({column}) => {
            const eduObjIdx = warningColumns.findIndex(obj => obj === column.id)
            return ({
                sx: {
                    // '& .Mui-TableHeadCell-Content': {
                    //     borderTop: (eduObjIdx > -1) ? '1px solid red': undefined,
                    // },
                    color: (eduObjIdx > -1) ? 'red': undefined,
                    '& .MuiBox-root.css-1lderhz svg': {
                        color: column.getIsFiltered() ? 'limegreen !important' : undefined
                    },
                    '& .MuiTableSortLabel-root svg': {
                        color: column.getIsSorted() ? 'limegreen !important' : undefined
                    }
                }
            })
        },

        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },

        enableEditing: true,
        editDisplayMode: 'cell',
        // muiEditTextFieldProps: {
        //     variant: 'outlined'
        // },
        //onEditingRowSave: (handleSaveRow),
        muiEditTextFieldProps: ({cell}) => ({
            onBlur: event => {
                handleSaveCell(cell, event.target.value);
            },
            onChange: event => console.log('all col onChange', event.target.value)
        }),

        // muiTableProps: {
        //     sx: {
        //         captionSide: 'top'
        //     }
        // },
        // renderCaption: ({table}) => {
        //     return ('Table Caption')
        // }

        // muiTableBodyRowProps: ({row}) => (
        //     {
        //         sx: {
        //             backgroundColor: 'red'
        //         }
        //     }
        // )

        // muiTableBodyCellProps: ({cell, column, row, table}) => {
        //     //const meta = JSON.parse(row['meta'])
        //     // const test = row.original.meta
        //     // console.log(test)
        //     //console.log('C:'+column.id+' meta='+row.original.meta)
        //     const test = row.original.meta.indexOf('temperature')
        //     console.log(test)
        //     return (
        //         {
        //             sx: {
        //                 color:'black'
        //             }
        //         }
        //     )
        // }

        // muiTableHeadCellProps: ({table, column}) => {
        //     console.log(column)
        //     return (
        //         {
        //             sx: {
        //                 color:'green'
        //             }
        //         }
        //     )
        // }

        //enableCellActions: true,
        // renderCellActionMenuItems: ({
        //         closeMenu,
        //         cell,
        //         row,
        //         table,
        //         internalMenuItems,
        //         }) => [
        //             ...internalMenuItems, //render the copy and edit actions wherever you want in the list
        //             <Divider />, //optionally place a Menu Divider to separate groups of actions
        //             <MRT_ActionMenuItem
        //             icon={<Email />}
        //             key={1}
        //             label="Item 1"
        //             onClick={() => {
        //                 //your logic here
        //                 closeMenu(); //close the menu after the action is performed
        //             }}
        //             table={table}
        //             />,
        //             <MRT_ActionMenuItem
        //             icon={<PersonOffOutlined />}
        //             key={2}
        //             label="Item 2"
        //             onClick={async () => {
        //                 //await your logic here
        //                 closeMenu(); //close the menu after the async action is performed
        //             }}
        //             table={table}
        //             />,
        //         ],
    });

    // const blockedDivecesTable = useMaterialReactTable({
        // columns: blockedColumns,
        // data: [],
        // enablePagination: false,
        // enableRowNumbers: true,
        // enableDensityToggle: false,
        // columnFilterDisplayMode: 'popover',
        // enableColumnFilterModes: true,
        // enableFacetedValues: true,
        // enableColumnResizing: true,
        // layoutMode: 'grid',
        // enableEditing: false,

        // enableRowActions: true,
    //     // renderRowActionMenuItems: ({ closeMenu, row }) => [
    //     //     <MenuItem key="delete_action" onClick={() => {closeMenu(); openDeleteConfirmModal(row)}}>
    //     //         <DeleteIcon /> Delete
    //     //     </MenuItem>
    //     // ],
    // })

    useEffect(() => {
        const columnVisibility = localStorage.getItem(
            'mrt_columnVisibility_table_1',
        );

        const columnOrder = localStorage.getItem(
            'mrt_columnOrder_table_1',
        );

        if (columnVisibility) {
            setColumnVisibility(JSON.parse(columnVisibility));
        }

        if (columnOrder) {
            setColumnOrder(JSON.parse(columnOrder));
        }
    }, []);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem(
            'mrt_columnVisibility_table_1',
            JSON.stringify(columnVisibility),
        );
    }, [columnVisibility]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem(
            'mrt_columnOrder_table_1',
            JSON.stringify(columnOrder),
        );
    }, [columnOrder]);
    
    useEffect( () => { 
        const fetchAreasData = async () => {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setAreas(result.payload.data)
        }

        fetchAreasData();

        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        const fetchDevicePropertiesList = async (area_uuid) => {
            setIsDevicePropertiesLoading(true)

            if(_deviceBlockedListRequest_AbortController.current)  {
                _deviceBlockedListRequest_AbortController.current.abort()
            }

            if(_workersPropertyListRequest_AbortController.current)  {
                _workersPropertyListRequest_AbortController.current.abort()
            }

            if(_devicePropertyListRequest_AbortController.current)  {
                _devicePropertyListRequest_AbortController.current.abort()
            }
            _devicePropertyListRequest_AbortController.current = new AbortController()

            const result = await dispatch(getDevicePropertiesList(area_uuid, _devicePropertyListRequest_AbortController.current))
            if(!result.success) {
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }
            
            processingData(result.payload.data)
            setDeviceProperties(result.payload.data)
            setDevicePropertiesSource(result.payload.data)
        }

        const fetchWorkersPropertiesList = async (area_uuid) => {
            setIsWorkersPropertiesLoading(true)

            if(_devicePropertyListRequest_AbortController.current)  {
                _devicePropertyListRequest_AbortController.current.abort()
            }

            if(_deviceBlockedListRequest_AbortController.current)  {
                _deviceBlockedListRequest_AbortController.current.abort()
            }

            if(_workersPropertyListRequest_AbortController.current)  {
                _workersPropertyListRequest_AbortController.current.abort()
            }

            _workersPropertyListRequest_AbortController.current = new AbortController()

            const result = await dispatch(getWorkersPropertiesList(area_uuid, _workersPropertyListRequest_AbortController.current))
            if(!result.success) {
                setWorkersProperties([])
                setIsWorkersPropertiesLoading(false)
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }
            
            setWorkersProperties(result.payload.data)
            setIsWorkersPropertiesLoading(false)
        }

        const fetchBlockedDevicePropertiesList = async (area_uuid) => {
            setIsBlockedDevicePropertiesLoading(true)

            if(_devicePropertyListRequest_AbortController.current)  {
                _devicePropertyListRequest_AbortController.current.abort()
            }

            if(_workersPropertyListRequest_AbortController.current)  {
                _workersPropertyListRequest_AbortController.current.abort()
            }

            if(_deviceBlockedListRequest_AbortController.current)  {
                _deviceBlockedListRequest_AbortController.current.abort()
            }
            _deviceBlockedListRequest_AbortController.current = new AbortController()

            const result = await dispatch(getBlockedDevicePropertiesList(area_uuid, _deviceBlockedListRequest_AbortController.current))
            if(!result.success) {
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }
            
            setBlockedDevicePropertiesSource(result.payload.data)

            setIsBlockedDevicePropertiesLoading(false)
        }

        if(areas.length > 0)    {
            console.log(showBlockedDevices)
            if(showBlockedDevices === 0) {
                table.reset()
                fetchDevicePropertiesList(areas[areaIndex].id);
            } if(showBlockedDevices === 1) {
                table.reset()
                fetchWorkersPropertiesList(areas[areaIndex].id);
            } if(showBlockedDevices === 2)  {
                table.reset()
                fetchBlockedDevicePropertiesList(areas[areaIndex].id);
            }
        }
    },[areas, areaIndex, showBlockedDevices, muiTableKey])

    useEffect(() => {
        setIsDevicePropertiesLoading(false)
    }, [warningColumns])

    const updateBlockedDevice = async () => {
        const fetchBlockedDevicePropertiesList = async (area_uuid) => {
            setIsBlockedDevicePropertiesLoading(true)

            if(_devicePropertyListRequest_AbortController.current)  {
                _devicePropertyListRequest_AbortController.current.abort()
            }

            if(_workersPropertyListRequest_AbortController.current)  {
                _workersPropertyListRequest_AbortController.current.abort()
            }

            if(_deviceBlockedListRequest_AbortController.current)  {
                _deviceBlockedListRequest_AbortController.current.abort()
            }
            _deviceBlockedListRequest_AbortController.current = new AbortController()

            const result = await dispatch(getBlockedDevicePropertiesList(area_uuid, _deviceBlockedListRequest_AbortController.current))
            if(!result.success) {
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }
            
            setBlockedDevicePropertiesSource(result.payload.data)

            setIsBlockedDevicePropertiesLoading(false)
        }

        if(areas.length > 0)    {
            table.reset()
            fetchBlockedDevicePropertiesList(areas[areaIndex].id);
        }
    }

    const processingData = async (data) => {
        const warning_properties = new Set();

        for(const device of data)   {
            const meta = JSON.parse(device.meta)
            const keys = new Set([...Object.keys(meta)]);
            keys.forEach(warning_properties.add, warning_properties);
        }

        setWarningColumns([...warning_properties])

        // setColumns(prevState => {
        //     for(const prop of warning_properties)   {
        //         const eduObjIdx = prevState.findIndex(obj => obj.id === prop)
        //         if(eduObjIdx >= 0)  {
        //             console.log(prevState[eduObjIdx]['muiTableHeadCellProps'])
        //             prevState[eduObjIdx]['muiTableHeadCellProps'] = { sx: { color: "red" } }
        //         }
        //     }
        //     return [
        //         ...prevState
        //     ]
        // })
    }

    const onSwitchArea = async (index) => {
        setAreaIndex(index)
    }

    const getSelectedMacsFromTable = (table) => {
        const rowSelection = table.getState().rowSelection; //read state
        const selectedRows = table.getSelectedRowModel().rows; //or read entire rows

        const macs = new Set()
        for (const row of selectedRows) {
            macs.add(row.getValue('mac'))
        }

        return [...macs]
    }

    const onRebootDevices = async (macs) => {
        const alertText = macs.length > 1 ? 'Выбранные устройства будут перезагружены' : 'Устройство ' + macs[0] + ' будет перезагружено'

        Swal.fire({
            title: 'Вы уверены?',
            text: alertText,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да, перезагрузить'
          }).then((result) => {
            if (result.isConfirmed) {
                onRebootDevice2(macs)
            }
          })
    }
    const onRebootDevice2 = async (macs) => {
        const result = await dispatch(rebootDevice(areas[areaIndex].id, macs))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            //const task_id = result.payload.data['task_id']
            onRebootDeviceRequest('task_id')
        }
    }
    const onRebootDeviceRequest = (task_id) => {
        let timerInterval
        Swal.fire({
        title: 'Ожидаем завершение задачи ',
        html: '<b></b>',
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
                b.textContent = (Swal.getTimerLeft()/1000).toFixed(0)
            }, 1000)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                onRebootDevice3(task_id)
            } else if(result.dismiss == Swal.DismissReason.backdrop)    {
                //onRebootDevice3()
            }
        })
    }
    const onRebootDevice3 = async (task_id) => {
        Swal.fire(
            'Выполнено!',
            'Устройство было перезагружено.',
            'success'
        )
    }

    const onPingDevices = async (macs) => {
        let timerInterval
        Swal.fire({
            title: 'Ping',
            html: '<b></b> milliseconds.',
            timer: 1000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            onPingDeviceRequest(macs)
        })
    }
    const onPingDeviceRequest = async (mac) => {
        const result = await dispatch(pingDevice(areas[areaIndex].id, mac))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            const _task_created = result.payload.data['task_created']
            const _task_id = result.payload.data['task_id']
            const _task_type = result.payload.data['task_type']

            // _task_created = last or now
            if(_task_created == 'last') {
                Swal.fire(
                    'Очередь занята',
                    `Предыдущая задача ${_task_type}, в процессе выполнения. Попробуйте чуть позже.`,
                    'warning')
            }
        }
    }

    const onChangeBeingMode = async (macs, mode_type) => {
        let alertText = ''
        if(mode_type === 'sleep')   {
            alertText = 'Выбранные устройства будут переведены в спящий режим'
        } else if(mode_type === 'normal')   {
            alertText = 'Выбранные устройства будут разбужены'
        } else if(mode_type === 'low_power')   {
            alertText = 'Выбранные устройства будут переведены в режим пониженного энергопотребления'
        }

        Swal.fire({
            title: 'Вы уверены?',
            text: alertText,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да'
          }).then((result) => {
            if (result.isConfirmed) {
                onChangeBeingMode2(macs, mode_type)
            }
          })
    }
    const onChangeBeingMode2 = async (mac, mode_type) => {
        const result = await dispatch(changeBeingModeForDevice(areas[areaIndex].id, mac, mode_type))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        } else  {
            const _task_created = result.payload.data['task_created']
            const _task_id = result.payload.data['task_id']
            const _task_type = result.payload.data['task_type']

            if(_task_created == 'now')  {
                if(mode_type === 'sleep')   {
                    Swal.fire(
                        'Задача отправлена в очередь',
                        'Устройства переводятся в спящий режим.',
                        'success')
                } else if(mode_type === 'normal')   {
                    Swal.fire(
                        'Задача отправлена в очередь',
                        'Устройства переводятся в активный режим.',
                        'success')
                } else if(mode_type === 'low_power')    {
                    Swal.fire(
                        'Задача отправлена в очередь',
                        'Устройства переводятся в режим пониженного энергопотребления.',
                        'success')
                }
            } else  {
                Swal.fire(
                    'Очередь занята',
                    `Предыдущая задача ${_task_type}, в процессе выполнения. Попробуйте чуть позже.`,
                    'warning')
            }
            // if(mode_type === 'sleep')   {
            //     Swal.fire(
            //         'Выполнено!',
            //         'Устройства были переведены в спящий режим.',
            //         'success')
            // } else if(mode_type === 'normal')   {
            //     Swal.fire(
            //         'Выполнено!',
            //         'Устройства активированы.',
            //         'success')
            // } else if(mode_type === 'low_power')    {
            //     Swal.fire(
            //         'Выполнено!',
            //         'Устройства переведены в режим пониженного энергопотребления.',
            //         'success')
            // }
        }
    }

    const onShowHashrateHistory = async (mac) => {
        setSelectedDevice({
            'area_id': areas[areaIndex].id,
            'mac': mac
        })
    }

    const onShowDevicePanel = async (mac) => {
        setSelectedDeviceForAsicPanel({
            'area_id': areas[areaIndex].id,
            'mac': mac
        })
    }

    const onApproveDevice = (row) => {
        async function _approveDeviceRequest(area_id, host_id, subnet_id) {
            const result = await dispatch(approveDeviceRequest(area_id, host_id, subnet_id))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                setMuiTableKey(muiTableKey + 1)
                const alertMessage = `Статус: Доверенное</br><b>MAC</b>: ${result.payload.data['mac']}</br><b>Static IP</b>: ${result.payload.data['address']}</br><b>Reboot status</b>: ${(result.payload.data['rebooted'])?'Перезагружен':result.payload.data['reboot_error']}`
                Swal.fire({
                    title: 'Выполнено!',
                    icon: 'success',
                    html: alertMessage
                })
            }
        }

        let inputOptionsDict = {
            asic: 'Асики',
            net: 'Сетевое',
            device: 'Девайсы',
            webcam: 'Камеры и рег',
        }
        if(areas[areaIndex].id == 'fcaed31c-60c5-4bbe-89cc-cf08025561aa')   {
            inputOptionsDict = {
                asic: 'Асики',
                net: 'Сетевое',
                device: 'Генераторы',
                webcam: 'Камеры и рег',
            }
        }

        const alertText = `Устройство ${row.original.mac_address} будет переведено в доверенный статус`
        Swal.fire({
            title: 'Вы уверены?',
            text: alertText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да',
            input: "select",
            inputOptions: inputOptionsDict,
            inputPlaceholder: 'Укажите подсеть',
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value.length !== 0) {
                        resolve();
                    } else {
                        resolve("Нет, всё же я настаиваю...");
                    }
                });
            }
        }).then((result) => {
            if(result.isConfirmed)  {
                const subnet_id = result.value
                _approveDeviceRequest(areas[areaIndex].id, row.original.id, subnet_id)
            }
        })
    };

    const onRejectDevice = (ip) => {
        async function _rejectDeviceRequest(area_id, ip) {
            const result = await dispatch(rejectDeviceRequest(area_id, ip))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            } else  {
                setMuiTableKey(muiTableKey + 1)
                const alertMessage = `Статус: Заблокировано</br><b>MAC</b>: ${result.payload.data['mac']}</br><b>Static IP</b>: ${result.payload.data['address']}</br><b>Reboot status</b>: ${(result.payload.data['rebooted'])?'Перезагружен':result.payload.data['reboot_error']}`
                Swal.fire({
                    title: 'Выполнено!',
                    icon: 'success',
                    html: alertMessage
                })
            }
        }

        const alertText = `Устройство ${ip} будет переведено в категорию недоверенных`
        Swal.fire({
            title: 'Вы уверены?',
            text: alertText,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да'
          }).then((result) => {
            if (result.isConfirmed) {
                _rejectDeviceRequest(areas[areaIndex].id, ip)
            }
          })
    };

    // const approveDevice = (area_id, host_id) => {
    //     async function _approveDeviceRequest(area_id, host_id) {
    //         const result = await dispatch(approveDeviceRequest(area_id, host_id))
    //         if(!result.success) {
    //             return Swal.fire({text:result.message, icon: 'error'});
    //         } else  {
    //             const alertMessage = `MAC: ${result.payload.data['mac']}\nIP: ${result.payload.data['address']}\nReboot status: ${(result.payload.data['rebooted'])?'Перезагружен':result.payload.data['reboot_error']}`
    //             Swal.fire(
    //                 'Выполнено!',
    //                 alertMessage,
    //                 'success')
    //         }
    //     }

    //     _approveDeviceRequest(area_id, host_id)
    // }

    return (
        <>
            <div style={{position:'sticky', left:'0', top:'-10px', background: '#ffffff', zIndex:1}}>
                <div className='columns is-desktop is-fullwidth'>
                    <div className='column is-full'>
                        <div className="columns is-desktop is-centered">
                            <div className="column is-6 is-offset-0">
                                {(areas.length > 1)?(
                                    <nav className="breadcrumb has-bullet-separator is-centered mt-3" aria-label="breadcrumbs">
                                        <ul>
                                            {
                                                areas.map((item, index) => {
                                                    return (
                                                        <li key={index} className={(areaIndex == index) ? "is-active": ""}>
                                                            <a href="#" onClick={()=>{onSwitchArea(index)}}>{item.name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </nav>):(
                                        null
                                    )
                                }
                                <nav className="breadcrumb has-bullet-separator is-centered mt-3" aria-label="breadcrumbs">
                                        <ul>
                                            <li key={0} className={(showBlockedDevices == 0) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{setShowBlockedDevices(0)}}>{'Асики'}</a>
                                            </li>
                                            <li key={1} className={(showBlockedDevices == 1) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{setShowBlockedDevices(1)}}>{'Воркеры'}</a>
                                            </li>
                                            <li key={2} className={(showBlockedDevices == 2) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{setShowBlockedDevices(2)}}>{'Роутер'}</a>
                                            </li>
                                        </ul>
                                    </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                (showBlockedDevices == 0)?(
                    <MaterialReactTable key='fffds1' table={table}/>
                ):(
                    (showBlockedDevices == 1)?(
                        <MaterialReactTable 
                            key='ffdds2'
                            columns = {workerColumns}
                            data={workersProperties}
                            enablePagination={false}
                            enableRowNumbers={true}
                            enableDensityToggle={false}
                            columnFilterDisplayMode={'popover'}
                            enableColumnFilterModes={true}
                            enableFacetedValues={true}
                            enableColumnResizing={true}
                            layoutMode={'grid'}
                            enableEditing={false}
                            enableRowActions={false}
                            state={{
                                isLoading: isWorkersPropertiesLoading,
                                showProgressBars: isWorkersPropertiesLoading,
                                density: 'compact',
                            }}
                        />
                    ) : (
                        <MaterialReactTable 
                            key='ffdds3'
                            columns = {blockedColumns}
                            data={blockedDevicePropertiesSource}
                            enablePagination={false}
                            enableRowNumbers={true}
                            enableDensityToggle={false}
                            columnFilterDisplayMode={'popover'}
                            enableColumnFilterModes={true}
                            enableFacetedValues={true}
                            enableColumnResizing={true}
                            layoutMode={'grid'}
                            enableEditing={false}
                            enableRowActions={true}
                            state={{
                                isLoading: isBlockedDevicePropertiesLoading,
                                showProgressBars: isBlockedDevicePropertiesLoading,
                                density: 'compact',
                            }}
                            renderRowActionMenuItems={({ closeMenu, row }) => {
                                if(row.original.dynamic === 'true') {
                                    return [
                                        <MenuItem key="approve_action" onClick={() => {
                                                console.log(row.original)
                                                closeMenu();
                                                onApproveDevice(row)
                                            }}>
                                            <span className="icon is-small">
                                                <ThumbIcon/>
                                            </span>
                                            <span className='ml-3'>Разрешить</span>
                                        </MenuItem>
                                    ]
                                } else  {
                                    return [
                                        <MenuItem key="reject_action" onClick={() => {
                                                console.log(row.original)
                                                closeMenu();
                                                onRejectDevice(row.original.address)
                                            }}>
                                            <span className="icon is-small">
                                                <ThumbDownIcon/>
                                            </span>
                                            <span className='ml-3'>Запретить</span>
                                        </MenuItem>
                                    ]
                                }
                            }}
                            renderTopToolbarCustomActions = { ({table}) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            padding: '8px',
                                            flexWrap: 'wrap',
                                            flexDirection:'row',
                                            justifyContent:'space-between'
                                        }}>
                                        <Typography variant="h6">Выборка устройств: {table.getRowCount()}</Typography>
                                        <Button
                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                            onClick={()=>{updateBlockedDevice()}}
                                            startIcon={<RefreshIcon />}>
                                            Refresh 
                                        </Button>
                                        <Button
                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                            onClick={handleExportBlockedDeviceData}
                                            startIcon={<FileDownloadIcon />}>
                                            Export All Data
                                        </Button>
                                    </Box>)
                            }}
                        />
                    )
                )
            }

            <DeviceContext.Provider value={{selectedDevice, setSelectedDevice}}>
                <DeviceHashrateHistory data={{}} onFinish={()=>{}}/>
            </DeviceContext.Provider>

            <DeviceContext.Provider value={{selectedDeviceForAsicPanel, setSelectedDeviceForAsicPanel}}>
                <DeviceAsicPanel data={{}} onFinish={()=>{}}/>
            </DeviceContext.Provider>

            {/* <LogModal open={openLogView} handleClose={handleLogViewClose} logData={logData} loading={loadingLogData} /> */}
            <DeviceLogViewer open={openLogViewer} onClose={handleCloseLogViewer} />
        </>
    )
}