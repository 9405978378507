import { React, Component, useState, useEffect, createContext } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import Pagination from 'bulma-pagination-react';
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

import { getIsAreasProcessing } from '../../store/selectors';
import { getProfitConsumptionRange, getProfitableConsumptionSamplingRange } from '../../store/actions';
import RocketCalendar from '../../components/Calendar/RocketCalendar';

export default function ProfitabilityStats({data}) {
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Вчера', is_active: true, sampling:'1h'},
        {title:'Неделя', is_active: false, sampling:'1d'},
        {title:'Месяц', is_active: false, sampling:'1d'}
    ]

    const COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];

    const [title, setTitle] = useState(data.name);
    const [uuid] = useState(data.id);

    const [counterConsumptions, setCounterConsumptions] = useState({});
    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);

    const [areaIndex, setAreaIndex] = useState(1);
    const [startDateValue, setStartDateValue] = useState(moment().subtract(1, 'day').toDate())
    const [endDateValue, setEndDateValue] = useState(new Date())
    const [calendarVisible, setCalendarVisible] = useState({visible:false, mode:'start', date:startDateValue});

    const [counterConsumptionSamplingRangeResonse, setCounterConsumptionSamplingRangeResponse] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [currencyIndex, setCurrencyIndex] = useState(0);

    const fetchProfitConsumptionRange = async (id, startDate, endDate) => {
        setIsLoading(true)
        setCounterConsumptions({})

        console.log('start: '+startDate+' end: '+endDate)
        const result = await dispatch(getProfitConsumptionRange(id, startDate, endDate))
        setIsLoading(false)
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        setCounterConsumptions(result.payload.data)
    }

    const fetchCounterConsumptionSamplingRange = async (id, sampling, startDate, endDate) => {
        setIsChartLoading(true)
        const result = await dispatch(getProfitableConsumptionSamplingRange(id, sampling, startDate, endDate))
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        const samplingData = result.payload.data
        setCounterConsumptionSamplingRangeResponse(samplingData)
        
        transformConsumptionSamplingRange(samplingData, 'rub')
        // let times = Array()
        // let dataSample = []
        // let income = []
        // let outcome = []
        // let profit = []
        // Object.keys(samplingData).map((value, index) => {
        //     const humanTime = moment.unix(value).subtract(3, 'hours').format((dateIndex == 0)?"DD/MM/YYYY HH:00":"DD/MM/YYYY")
        //     times.push(humanTime)

        //     const dataCons = samplingData[value]
            
        //     income.push(dataCons['income_rub'])
        //     outcome.push(dataCons['outcome_rub'])
        //     profit.push(dataCons['profit_rub'])
        // })

        // dataSample = [
        //     {
        //         label: 'income',
        //         data: income,
        //         stack: 'Stack 1',
        //         backgroundColor:COLORS[0]
        //     },
        //     {
        //         label: 'outcome',
        //         data: outcome,
        //         stack: 'Stack 2',
        //         backgroundColor:COLORS[1]
        //     },
        //     {
        //         label: 'profit',
        //         data: profit,
        //         stack: 'Stack 3',
        //         backgroundColor:COLORS[2]
        //     }
        // ]

        // setIsChartLoading(false)
        // setChartLabels(times)
        // console.log(dataSample)
        // setChartData(dataSample)
    }

    const transformConsumptionSamplingRange = async (data, currency='rub') => {
        let times = Array()
        let dataSample = []
        let income = []
        let outcome = []
        let profit = []
        Object.keys(data).map((value, index) => {
            const date = new Date(value*1000).toUTCString()
            const humanTime = moment(date).utc().format((dateIndex == 0)?"DD/MM/YYYY HH:00":"DD/MM/YYYY")
            //moment.unix(value).subtract(3, 'hours').format((dateIndex == 0)?"DD/MM/YYYY HH:00":"DD/MM/YYYY")
            //const humanTime = moment.unix(value).format((dateIndex == 0)?"DD/MM/YYYY HH:00":"DD/MM/YYYY")
            times.push(humanTime)

            const dataCons = data[value]
            
            income.push(dataCons['income_'+currency])
            outcome.push(dataCons['outcome_'+currency])
            profit.push(dataCons['profit_'+currency])
        })

        dataSample = [
            {
                label: 'income',
                data: income,
                stack: 'Stack 1',
                backgroundColor:COLORS[0]
            },
            {
                label: 'outcome',
                data: outcome,
                stack: 'Stack 2',
                backgroundColor:COLORS[1]
            },
            {
                label: 'profit',
                data: profit,
                stack: 'Stack 3',
                backgroundColor:COLORS[2]
            }
        ]

        setIsChartLoading(false)
        setChartLabels(times)
        setChartData(dataSample)
    }

    useEffect( () => {
        var now = new Date();

        let startDate;
        let endDate;
        switch(dateIndex){
            case 0:
                // весь день вчера
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            case 1:
                // 7 дней до всего вчера
                startDate = moment(now).subtract(7, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            case 2:
                //30 дней до всего вчера
                startDate = moment(now).subtract(30, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            default:
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now) //.subtract(1, 'days')
        }

        
        var startDateString = startDate.format('YYYY-MM-DDT00:00:00');
        var endDateString = endDate.format('YYYY-MM-DDT00:00:00');
        //var endDateString = endDate.format('YYYY-MM-DDT23:59:59');
        let sampling = dateList[dateIndex]['sampling']

        if(areaIndex == 0)  {
            setCounterConsumptions({})
        } else  {
            fetchProfitConsumptionRange(uuid, startDateString, endDateString)
            fetchCounterConsumptionSamplingRange(uuid, sampling, startDateString, endDateString)
        }
    }, [areaIndex, dateIndex]);

    // useEffect( () => {
    //     const startDateString = moment(startDateValue).format('YYYY-MM-DDT00:00:00');
    //     const endDateString = moment(endDateValue).format('YYYY-MM-DDT23:59:59');

    //     let sampling = dateList[dateIndex]['sampling']

    //     fetchCounterConsumptionSamplingRange(areas[areaIndex].id, sampling, startDateString, endDateString)
    // }, [dateIndex]);

    const onSwitchStage = async (index) => {
        setDateList([
            {title:'Вчера', is_active: (index == 0), sampling:'1h'},
            {title:'Неделя', is_active: (index == 1), sampling:'1d'},
            {title:'Месяц', is_active: (index == 2), sampling:'1d'}
        ])
        setDateIndex(index)
        //setDateRangeByIndex(index)
    }

    // const setDateRangeByIndex = async (index) => {
    //     var now = new Date();

    //     let startDate;
    //     let endDate;
    //     switch(index){
    //         case 0:
    //             startDate = moment(now).subtract(1, 'days')
    //             endDate = moment(now)
    //             break
    //         case 1:
    //             startDate = moment(now).subtract(7, 'days')
    //             endDate = moment(now)
    //             break
    //         case 2:
    //             startDate = moment(now).subtract(30, 'days')
    //             endDate = moment(now)
    //             break
    //         default:
    //             startDate = moment(now).subtract(1, 'days')
    //             endDate = moment(now)
    //     }

        
    //     var startDateString = startDate.format('YYYY-MM-DD');
    //     var endDateString = endDate.format('YYYY-MM-DD');
    //     setDateRange({start:startDateString, end:endDateString})
    // }

    const onChangeValueCalendar = async (settings) => {
        if(settings.mode == 'start')    {
            setStartDateValue(settings.date)
        } else if(settings.mode == 'end')   {
            setEndDateValue(settings.date)
        }

        setCalendarVisible(settings)
    }

    const onSwitchArea = async (index) => {
        setAreaIndex(index)
    }

    const onSwitchCurrencyIndex = async (index) => {
        setCurrencyIndex(index)

        transformConsumptionSamplingRange(counterConsumptionSamplingRangeResonse, (index == 0)?'rub':'usd')
    }

    return (
        <>
        <div className="column is-full">
            <div className="columns is-multiline is-centered">
                <div className='column is-full'>

                    {/* <div className="card mx-4 mt-4" style={{height:'40px'}}>
                        <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                            <nav className="breadcrumb has-bullet-separator is-centered" aria-label="breadcrumbs">
                                    <ul>
                                        <li key={0} className={(areaIndex == 0) ? "is-active": ""}>
                                            <a href="#" onClick={()=>{onSwitchArea(0)}}>Все</a>
                                        </li>
                                        {
                                            areas.map((item, index) => {
                                                return (
                                                    <li key={index+1} className={(areaIndex == (index+1)) ? "is-active": ""}>
                                                        <a href="#" onClick={()=>{onSwitchArea(index+1)}}>{item.name}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                            </nav>
                        </div>
                    </div> */}
                    <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                        <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                            <ul>
                                {
                                    dateList.map((item, index) => {
                                        return (
                                            <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {
                (isLoading) ? (
                    <div className='hero is-flex is-centered'>
                        <div className="container has-text-centered mt-4"> 
                            <Oval
                                height={40}
                                width={40}
                                color="#4fa94d"
                                wrapperStyle={{}}
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#4fa94d"
                                strokeWidth={2}
                                strokeWidthSecondary={2}/>
                        </div>
                    </div>
                ) : (
                        (counterConsumptions.hasOwnProperty('income_today_rub'))?(
                            <nav className="level">
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">доход за период</p>
                                        <p className="title">{counterConsumptions['income_today_rub'].toFixed(2)} ₽</p>
                                        <p className="subtitle mt-1">{counterConsumptions['income_today_usd'].toFixed(2)} $</p>
                                    </div>
                                </div>
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">расход за период</p>
                                        <p className="title">{counterConsumptions['outcome_today_rub'].toFixed(2)} ₽</p>
                                        <p className="subtitle mt-1">{counterConsumptions['outcome_today_usd'].toFixed(2)} $</p>
                                    </div>
                                </div>
                                <div className="level-item has-text-centered">
                                    <div>
                                        <p className="heading">прибыль за период</p>
                                        <p className="title">{counterConsumptions['profit_today_rub'].toFixed(2)} ₽</p>
                                        <p className="subtitle mt-1">{counterConsumptions['profit_today_usd'].toFixed(2)} $</p>
                                    </div>
                                </div>
                                {/* {
                                    (counterConsumptions.hasOwnProperty('items') && counterConsumptions['items'].length > 0) ? (
                                        counterConsumptions['items'].map((item, index) => {
                                            return (
                                                <div className="level-item has-text-centered">
                                                    <div>
                                                        <p className="heading">Счетчик {index+1} №{item.serial_number}</p>
                                                        <p className="title">{item.consumption/1000}</p>
                                                        <p className="subtitle mt-1">кВт/ч</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </nav>
                        ) : (
                            <div className='hero is-flex is-centered'>
                                <div className="container has-text-centered mt-4"> 
                                    <>Нет данных</>
                                </div>
                            </div>
                        )
                )
            }
            {
                (areaIndex !== 0) ? (
                    <div className='is-flex is-flex-direction-row is-justify-content-center is-align-items-center' style={{height:'100%'}}>
                        {
                        (isChartLoading) ? (
                            <div className='hero is-flex is-centered'>
                                <div className="container has-text-centered mt-4"> 
                                    <Oval
                                        height={40}
                                        width={40}
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#4fa94d"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}/>
                                </div>
                            </div>
                        ) : (
                            <div style={{width:'80%', height:'80%'}}>
                                <Bar options={
                                    {
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'График потребления'
                                            },
                                        },
                                        responsive: true,
                                        interaction: {
                                            intersect: false,
                                        },
                                        scales: {
                                            x: {
                                                stacked: true,
                                            },
                                            y: {
                                                stacked: true
                                            }
                                        }
                                    }
                                } data={{
                                    labels: chartLabels,
                                    datasets: chartData
                                }}/>

                                <div className="buttons has-addons mt-3">
                                    <button className={(currencyIndex == 0) ? "button is-success is-selected": "button"} onClick={()=>{onSwitchCurrencyIndex(0)}}>RUB</button>
                                    <button className={(currencyIndex == 1) ? "button is-success is-selected": "button"} onClick={()=>{onSwitchCurrencyIndex(1)}}>USD</button>
                                </div>
                            </div>
                        )
                        }
                    </div>
                ):(
                    <></>
                )
            }

            {/* <RocketCalendar visible_settings={calendarVisible} handle={(settings)=>{onChangeValueCalendar(settings)}} /> */}
        </div>
        </>
    );
}

