import { React, Component, useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getAreaCounters, createCounter, deleteCounter, getRoomRanges, deleteRoom, createRoom } from '../../store/actions';
import { Alert, Avatar, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, styled, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { createPoolPort, deletePoolPort, getPoolPortList } from '../../store/actions/areas';

export default function PoolsPortComponent({data, onCounterClick}) {

    const dispatch = useDispatch();

    const [pools, setPools] = useState([]);
    const [name, setName] = useState('');

    useEffect( () => { 
        const fetchAreasCounters = async () => {
            const result = await dispatch(getPoolPortList(data.area_uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setPools(result.payload.data)
        }

        fetchAreasCounters();
    }, []);

    const onDelete = (pool_id) => {
        async function sendDeletePool(pool_id) {
            const result = await dispatch(
                deletePoolPort(data.area_uuid, 
                    pool_id)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setPools(pools.filter(item => item.id !== pool_id))
        }
        sendDeletePool(pool_id)
    }

    const onAddPool = () => {
        async function sendData() {
            if(name.length < 5) {
                return Swal.fire({text:'Введите имя пула', icon: 'error'});
            }
        
            const result = await dispatch(
                createPoolPort(data.area_uuid, name)
            )
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }

            setPools([...pools, result.payload.data])
            setName('')
        }

        sendData()
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    return (
        <div className='box'>
            <h3 className="title is-3">Пул/Порт</h3>
            <Typography sx={{ mt: 0, mb: 2 }} variant="h9" component="div">
                Укажите все доступные пулы поддерживаемые на данной площадке
            </Typography>

            {
                (pools.length > 0) ? (
                    <Grid container spacing={2} mb={3}>
                        <Grid item xs={12} md={6}>
                            <Demo>
                                <List dense={true}>
                                    {
                                        pools.map(item => {
                                            return (
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete" onClick={()=>{onDelete(item.id)}}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    } key={item.id}>
                                                    <ListItemText
                                                        primary={item.name}
                                                        secondary={null}
                                                    />
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Demo>
                        </Grid>
                    </Grid>
                ): (
                    <Alert severity="warning">Не указано ни одного пула</Alert>
                )
            }

            <div className="field">
                <label className="label"></label>
                <input className="input" 
                    type="text" 
                    placeholder="stratum+tcp://xxx.xxx.xx.xxx:port"
                    value={name || ''} 
                    onChange={(e) => setName(e.target.value)}/>
            </div>

            <div className="control">
                <button className="button is-primary" onClick={onAddPool}>Добавить</button>
            </div>
        </div>
    )
}