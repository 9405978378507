import HttpRequest from "./HttpRequest";

class AreasProvider extends HttpRequest {
    async fetchAreasList()   {
        return await this.get('/areas')
    }

    async updateArea(id, 
        name, 
        deviceCount, 
        referenceHashrate, 
        critical_hashrate, 
        percent_hashrate,
        referenceHashrateLType,
        percentHashrateLType,  
        time_dissapear, 
        temp_chip_min, 
        temp_chip_max,
        fan_speed_min,
        fan_speed_max,
        voltage_chip_min,
        voltage_chip_max,  
        tids,
        hhKey,
        ipRouter,
        USAPoolSubaccountName,
        USAPoolXApiKey,
        electricity_cost, 
        f2pool_currency, 
        f2pool_subaccount_name,
        influxDeviceHost,
        influxDevicePort,
        influxDeviceToken,
        influxDeviceOrg,
        influxDeviceBucket,
        influxCounterHost,
        influxCounterPort,
        influxCounterToken,
        influxCounterOrg,
        influxCounterBucket,
        influxBackupHost,
        influxBackupPort,
        influxBackuprToken,
        influxBackupOrg,
        influxBackupBucket)   {

        let body = {
            id,
            name,
            device_count: deviceCount,
            reference_hashrate: referenceHashrate,
            critical_hashrate: critical_hashrate,
            percent_hashrate: percent_hashrate,
            reference_hashrate_ltype:referenceHashrateLType,
            percent_hashrate_ltype:percentHashrateLType, 
            time_dissapear: time_dissapear,
            temp_chip_min: temp_chip_min,
            temp_chip_max: temp_chip_max,
            fan_speed_min: fan_speed_min,
            fan_speed_max: fan_speed_max,
            voltage_chip_min: voltage_chip_min,
            voltage_chip_max: voltage_chip_max,
            tids: tids,
            hh_key: hhKey,
            ip_router: ipRouter,
            fusapool_subaccount_name: USAPoolSubaccountName,
            fusapool_xapi_key: USAPoolXApiKey,
            electricity_cost:electricity_cost
        }

        if(f2pool_currency.length > 0 && f2pool_subaccount_name.length > 0) {
            body['f2pool'] = {
                currency: f2pool_currency,
                subaccount: f2pool_subaccount_name
            }
        }

        body['device_influx'] = {
            host: influxDeviceHost,
            port: influxDevicePort,
            token: influxDeviceToken,
            org: influxDeviceOrg,
            bucket: influxDeviceBucket,
            enable:true
        }

        body['counter_influx'] = {
            host: influxCounterHost,
            port: influxCounterPort,
            token: influxCounterToken,
            org: influxCounterOrg,
            bucket: influxCounterBucket,
            enable:true
        }

        body['backup_influx'] = {
            host: influxBackupHost,
            port: influxBackupPort,
            token: influxBackuprToken,
            org: influxBackupOrg,
            bucket: influxBackupBucket,
            enable:true
        }

        console.log(body)

        return await this.put('/areas', body)
    }

    async fetchAreaPools(uuid)   {
        return await this.get('/f2pool', {area_id:uuid})
    }

    async createPool(uuid, currency, subaccount, currencyType, serviceType)   {
        return await this.post('/f2pool', {
            area_id:uuid, 
            currency:currency,
            subaccount:subaccount,
            currency_type: currencyType,
            service_type:serviceType
        })
    }

    async deletePool(area_id, pool_id)   {
        return await this.delete('/f2pool', {
            area_id:area_id, 
            f2pool_id:pool_id
        })
    }

    async fetchAreaCounters(uuid)   {
        return await this.get('/counter', {area_id:uuid})
    }

    async createCounter(uuid, serial, model, port, k)   {
        return await this.post('/counter', {
            area_id:uuid, 
            serial_number:serial,
            model_number:model,
            port: port,
            k:k
        })
    }

    async deleteCounter(area_id, counter_id)   {
        return await this.delete('/counter', {
            area_id:area_id, 
            counter_id:counter_id
        })
    }

    async fetchAreaCounterByUUID(uuid)   {
        return await this.get('/counter/'+uuid, {})
    }

    async createCounterK(area_uuid, counter_uuid, k)   {
        return await this.post('/counter/k', {
            area_id:area_uuid, 
            counter_id:counter_uuid,
            k:k
        })
    }

    async reportProfitableHistory(area_id, offset, limit)   {
        return await this.get('/reports/profitable/history', {
            area_id:area_id,
            offset:offset,
            limit:limit
        })
    }

    async reportProfitableCounnterHistory(area_id, time)   {
        return await this.get('/reports/profitable/counter/history', {
            area_id: area_id,
            time: time
        })
    }

    async reportProfitableShort(area_id, time)   {
        return await this.get('/reports/profitable/short', {
            area_id: area_id
        })
    }

    async counterHistory(area_id, offset, limit)   {
        return await this.get('/reports/counter/history', {
            area_id: area_id,
            offset: offset,
            limit: limit
        })
    }

    async fetchRoomRanges(uuid)   {
        return await this.get('/room', {area_id:uuid})
    }

    async createRoom(area_id, firstIP, lastIP, ipRange, name)   {
        return await this.post('/room', {
            area_id: area_id, 
            group_uuid: '-',
            group_name: '-',
            ip_uuid: '-',
            ip_first: firstIP,
            ip_last: lastIP,
            ip_range: ipRange,
            name: name,
        })
    }

    async deleteRoom(area_id, room_id)   {
        return await this.delete('/room', {
            area_id:area_id, 
            room_id:room_id
        })
    }

    async counterConsumptionRange(area_id, startDate, endDate)   {
        return await this.get('/reports/counter/consumption/range', {
            area_id: area_id,
            start_date: startDate,
            end_date: endDate
        })
    }

    async counterConsumptionSamplingRange(area_id, sampling, startDate, endDate)   {
        return await this.get('/reports/counter/consumption/range/history', {
            area_id: area_id,
            sampling: sampling,
            start_date: startDate,
            end_date: endDate
        })
    }

    async profitConsumptionRange(area_id, startDate, endDate)   {
        return await this.get('/reports/profitable/consumption/range', {
            area_id: area_id,
            start_date: startDate,
            end_date: endDate
        })
    }

    async profitConsumptionSamplingRange(area_id, sampling, startDate, endDate)   {
        console.log(area_id+' '+sampling+' '+startDate+' '+endDate)
        return await this.get('/reports/profitable/consumption/range/history', {
            area_id: area_id,
            sampling: sampling,
            start_date: startDate,
            end_date: endDate
        })
    }

    async phaseExtendedConsumptionRange(area_id, startDate, endDate)   {
        return await this.get('/reports/phase/consumption_iup/range', {
            area_id: area_id,
            start_date: startDate,
            end_date: endDate
        })
    }

    async phaseConsumptionRange(area_id, phase_type, startDate, endDate)   {
        return await this.get('/reports/phase/consumption/range', {
            area_id: area_id,
            phase_type: phase_type,
            start_date: startDate,
            end_date: endDate
        })
    }

    async phaseConsumptionSamplingRange(area_id, phase_type, sampling, counter_sn, startDate, endDate)   {
        return await this.get('/reports/phase/consumption/range/history', {
            area_id: area_id,
            phase_type: phase_type,
            sampling: sampling,
            counter_sn: counter_sn,
            start_date: startDate,
            end_date: endDate
        })
    }

    async fetchAlertsUserList(area_id)   {
        return await this.get('/alert/priority', {
            area_id:area_id
        })
    }

    async setAlertData(area_id, data)   {
        return await this.post(`/alert/priority?area_id=${area_id}`, data)
    }

    async dashboardShortDataByRange(startDate, endDate)   {
        return await this.get('/reports/dashboard/profitable', {
            start_date: startDate,
            end_date: endDate
        })
    }

    async dashboardAreasStatusByRange(startDate, endDate)   {
        return await this.get('/reports/dashboard/areas/status', {
            start_date: startDate,
            end_date: endDate
        })
    }

    async fetchAreaHashrateHistory(area_uuid, range)   {
        return await this.get(`/areas/hashrate_history/${area_uuid}/${range}`, {})
    }

    async counterConsumptionRecalculate(area_id, counter_sn, startDate, endDate, amount)   {
        return await this.get('/reports/counter/consumption/recalculate', {
            area_id: area_id,
            counter_sn: counter_sn,
            start_date: startDate,
            end_date: endDate,
            amount: amount
        })
    }

    async fetchMonitors()   {
        return await this.get('/statuspage/monitor/list', {})
    }

    async fetchMonitorBeats(monitor_id)   {
        return await this.get(`/statuspage/monitor/beats/${monitor_id}`, {})
    }

    async fetchGGU()   {
        return await this.get('/statuspage/ggu/list', {})
    }

    async fetchGGUBeats(ggu_name)   {
        return await this.get(`/statuspage/ggu/beats/${ggu_name}`, {})
    }

    async createStatusPageEvent(area_id, monitor_id, type, timeStart, timeEnd, description)   {
        return await this.post(`/statuspage/event/add?area_id=${area_id}`, {
            type: type,
            monitor_id: monitor_id,
            start_time: timeStart,
            end_time: timeEnd,
            description: description,
        })
    }


    async fetchMaintenanceList()   {
        return await this.get('/statuspage/maintenance/list', {})
    }
    async fetchMonitorList()   {
        return await this.get('/statuspage/maintenance/monitor/list', {})
    }
    async addMaintenance(area_id, title, description, startDate, endDate, monitors)   {
        return await this.post(`/statuspage/maintenance/add?area_id=${area_id}`, {
            title: title,
            description: description,
            start_date: startDate,
            end_date: endDate,
            monitors: monitors
        })
    }
    async deleteMaintenance(area_id, maintenance_id)   {
        return await this.delete('/statuspage/maintenance/delete', {
            area_id:area_id, 
            maintenance_id:maintenance_id
        })
    }

    async fetchDeviceCountMonitor(area_id, from_timestamp = 0, to_timestamp=0)   {
        return await this.get(`/statuspage/monitor/device_count`, {
            area_id: area_id,
            from_timestamp: from_timestamp,
            to_timestamp: to_timestamp,
        })
    }

    async fetchPoolTransactions(area_uuid)   {
        return await this.get('/reports/profitable/pool/history', {
            area_id: area_uuid,
            offset: 0,
            limit: 0
        })
    }
}

export default AreasProvider;