import { React, Component, useState, useEffect, createContext, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import { getAreasList, getPoolTransactions } from '../../store/actions/areas';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { getCurrentUser } from '../../store/selectors';
import { getWorkersPropertiesList } from '../../store/actions/device';

export default function FriendWorkers({data}) {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => getCurrentUser(state))
    
    const [isLoading, setIsLoading] = useState(false);
    const [areas, setAreas] = useState([]);
    const [areaIndex, setAreaIndex] = useState(-1);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
    const [transactionsHistory, setTransactionsHistory] = useState([]);

    const [workersProperties, setWorkersProperties] = useState(false);
    const [isWorkersPropertiesLoading, setIsWorkersPropertiesLoading] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState({
        stale_15m: false,
        stale15mPercent: false,
        reject_15m: false,
        reject15mPercent: false,
        stale_1h: false,
        reject_1h: false
    });

    useEffect( () => { 
        const fetchWorkersPropertiesList = async (area_uuid) => {
            setIsWorkersPropertiesLoading(true)

            const result = await dispatch(getWorkersPropertiesList(area_uuid))
            if(!result.success) {
                setWorkersProperties([])
                setIsWorkersPropertiesLoading(false)
                if(result.message !== 'cancel_request') {
                    return Swal.fire({text:result.message, icon: 'error'});
                }
            }
            
            setWorkersProperties(result.payload.data)
            setIsWorkersPropertiesLoading(false)
        }

        const fetchAreasData = async () => {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                setIsLoading(false)
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setAreas(result.payload.data)
            setAreaIndex(0)
            setIsLoading(false)

            setIsLoadingTransactions(true)
            fetchWorkersPropertiesList(result.payload.data[0].id);
        }

        setIsLoading(true)
        fetchAreasData();
    }, []);

    const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);

    const columns = useMemo(() => [
        {
            id: 'name',
            header: 'name',
            accessorFn: (row) => row['workerName'],
            filterVariant: 'text',
        },
        {
            id: 'hashrate_5m',
            header: 'hashrate_5m (TH/s)',
            accessorFn: (row) => row['hashrate_5m'],
            filterVariant: 'text',
        },
        {
            id: 'hashrate_15m',
            header: 'hashrate_15m (TH/s)',
            accessorFn: (row) => row['hashrate_15m'],
            filterVariant: 'text',
        },
        {
            id: 'stale_15m',
            header: 'stale_15m (TH/s)',
            accessorFn: (row) => row['stale_15m'],
            filterVariant: 'text'
        },
        {
            id: 'stale15mPercent',
            header: 'stale15mPercent',
            accessorFn: (row) => row['stale15mPercent'],
            filterVariant: 'text',
        },
        {
            id: 'reject_15m',
            header: 'reject_15m',
            accessorFn: (row) => row['reject_15m'],
            filterVariant: 'text',
        },
        {
            id: 'reject15mPercent',
            header: 'reject15mPercent',
            accessorFn: (row) => row['reject15mPercent'],
            filterVariant: 'text',
        },
        // {
        //     id: 'rejectDetail_15m',
        //     header: 'rejectDetail_15m',
        //     accessorFn: (row) => row['rejectDetail_15m'],
        //     filterVariant: 'text',
        // },
        {
            id: 'hashrate_1h',
            header: 'hashrate_1h (TH/s)',
            accessorFn: (row) => row['hashrate_1h'],
            filterVariant: 'text',
        },
        {
            id: 'stale_1h',
            header: 'stale_1h',
            accessorFn: (row) => row['stale_1h'],
            filterVariant: 'text',
        },
        {
            id: 'reject_1h',
            header: 'reject_1h',
            accessorFn: (row) => row['reject_1h'],
            filterVariant: 'text',
        },
        // {
        //     id: 'rejectDetail_1h',
        //     header: 'rejectDetail_1h',
        //     accessorFn: (row) => row['rejectDetail_1h'],
        //     filterVariant: 'text',
        // },
        {
            id: 'lastShareTime',
            header: 'lastShareTime',
            accessorFn: (row) => row['lastShareTime'],
            filterVariant: 'text'
        },
        {
            id: 'updatedAt',
            header: 'updatedAt',
            accessorFn: (row) => row['updatedAt'],
            filterVariant: 'text'
        },
        // {
        //     id: 'tag',
        //     header: 'tag',
        //     accessorFn: (row) => row['tag'],
        //     filterVariant: 'text',
        // },
    ])

    const table = useMaterialReactTable({
        columns,
        data: workersProperties,
        enablePagination: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode: 'grid',
        enableEditing: false,
        enableRowActions: false,
        state: {
            isLoading: isWorkersPropertiesLoading,
            showProgressBars: isWorkersPropertiesLoading,
            density: 'compact',
            columnVisibility: columnVisibility
        },

        onColumnVisibilityChange: setColumnVisibility,
    })

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={1}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div>
                <MaterialReactTable table={table}/>
            </div>
        )
    )
}
