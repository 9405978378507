import { React, Component, useState, useEffect, createContext, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import { getPoolTransactions } from '../../store/actions/areas';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

export default function ProfitPool({data}) {
    const dispatch = useDispatch();

    const [title, setTitle] = useState(data.name);
    const [uuid] = useState(data.id);
    const [isLoading, setIsLoading] = useState(false);
    const [profitableHistory, setProfitableHistory] = useState([]);

    useEffect( () => { 
        const fetchAreasProfitableHistory = async () => {
            const result = await dispatch(getPoolTransactions(uuid))
            if(!result.success) {
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setProfitableHistory(result.payload.data)
            setIsLoading(false)
        }

        setIsLoading(true)
        fetchAreasProfitableHistory();
    }, []);

    const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);

    const columns = useMemo(() => [
        {
            id: 'time',
            header: 'Time',
            accessorFn: (row) => row['time'],
        },
        {
            id: 'type',
            header: 'Type',
            accessorFn: (row) => row['type'],
        },
        {
            id: 'amount',
            header: 'Amount',
            accessorFn: (row) => row['amount'],
            Cell: ({ cell }) => {
                return (
                    ''+cell.getValue()+' BTC'
                )
            }
        },
        {
            id: 'amount_usd',
            header: 'Amount USD',
            accessorFn: (row) => row['amount_usd'],
            Cell: ({ cell }) => {
                return (
                    ''+toCurrency(cell.getValue(), 'USD', 'en-us')
                )
            }
        },
        {
            id: 'amount_rub',
            header: 'Amount RUB',
            accessorFn: (row) => row['amount_rub'],
            Cell: ({ cell }) => {
                return (
                    ''+toCurrency(cell.getValue(), 'RUB', 'Ru-ru')
                )
            }
        },
        {
            id: 'hashrate_24h',
            header: 'avg. 24h (TH/s)',
            accessorFn: (row) => row['hashrate_24h'],
            enableClickToCopy: false,
        },
        {
            id: 'txId',
            header: 'txId',
            accessorFn: (row) => row['txId'],
            enableClickToCopy: true,
        },
        {
            id: 'address',
            header: 'address',
            accessorFn: (row) => row['address'],
            enableClickToCopy: true,
        }
    ], [])

    const table = useMaterialReactTable({
        columns,
        data: profitableHistory,
        enablePagination: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        columnFilterDisplayMode: 'popover',
        enableColumnFilterModes: true,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode: 'grid',
        enableEditing: false,
        enableRowActions: false,
        enableStickyHeader: true,
        rowPinningDisplayMode: 'select-sticky',
        // muiTableContainerProps: {
        //     sx: {
        //         maxHeight: '400px',
        //     },
        // },
    })

    return (
        <div className="column is-full">
            {(isLoading) ? (
                <div className="hero is-fullheight is-flex">
                    <div className="container has-text-centered mt-4"> 
                        <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperclassName=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                            />
                        <p className="mt-2">Загрузка</p>
                    </div>
                </div>) : (
                    <MaterialReactTable table={table}/>
                )}
        </div>
    );
}
