import { React, Component, useState, useEffect, createContext, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Oval } from  'react-loader-spinner'
import { getAreasList, getPoolTransactions } from '../../store/actions/areas';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { getCurrentUser } from '../../store/selectors';

export default function FriendDashboard({data}) {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => getCurrentUser(state))
    
    const [isLoading, setIsLoading] = useState(false);
    const [areas, setAreas] = useState([]);
    const [areaIndex, setAreaIndex] = useState(-1);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
    const [transactionsHistory, setTransactionsHistory] = useState([]);

    useEffect( () => { 
        const fetchAreasProfitableHistory = async (area_uuid) => {
            const result = await dispatch(getPoolTransactions(area_uuid))
            if(!result.success) {
                setIsLoadingTransactions(false)
                return Swal.fire({text:result.message, icon: 'error'});
            }
            
            setIsLoadingTransactions(false)
            setTransactionsHistory(result.payload.data)
        }

        const fetchAreasData = async () => {
            const result = await dispatch(getAreasList())
            if(!result.success) {
                setIsLoading(false)
                return Swal.fire({text:result.message, icon: 'error'});
            }
            setAreas(result.payload.data)
            setAreaIndex(0)
            setIsLoading(false)

            setIsLoadingTransactions(true)
            fetchAreasProfitableHistory(result.payload.data[0].id);
        }

        setIsLoading(true)
        fetchAreasData();
    }, []);

    const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);

    const columns = useMemo(() => [
        {
            id: 'time',
            header: 'Time',
            accessorFn: (row) => row['time'],
        },
        {
            id: 'type',
            header: 'Type',
            accessorFn: (row) => row['type'],
        },
        {
            id: 'amount',
            header: 'Amount',
            accessorFn: (row) => row['amount'],
            Cell: ({ cell }) => {
                return (
                    ''+cell.getValue()+' BTC'
                )
            }
        },
        {
            id: 'amount_usd',
            header: 'Amount USD',
            accessorFn: (row) => row['amount_usd'],
            Cell: ({ cell }) => {
                return (
                    ''+toCurrency(cell.getValue(), 'USD', 'en-us')
                )
            }
        },
        {
            id: 'amount_rub',
            header: 'Amount RUB',
            accessorFn: (row) => row['amount_rub'],
            Cell: ({ cell }) => {
                return (
                    ''+toCurrency(cell.getValue(), 'RUB', 'Ru-ru')
                )
            }
        },
        {
            id: 'txId',
            header: 'txId',
            accessorFn: (row) => row['txId'],
            enableClickToCopy: true,
        },
        {
            id: 'address',
            header: 'address',
            accessorFn: (row) => row['address'],
            enableClickToCopy: true,
        }
    ], [])

    const table = useMaterialReactTable({
        columns,
        state: {
            isLoading: isLoadingTransactions,
            showProgressBars: isLoadingTransactions,
            density: 'compact',
        },
        data: transactionsHistory,
        enablePagination: false,
        enableRowNumbers: true,
        enableDensityToggle: false,
        enableFacetedValues: true,
        enableColumnResizing: true,
        layoutMode: 'grid',
        enableEditing: false,
        enableRowActions: false,
        enableStickyHeader: true,
        rowPinningDisplayMode: 'select-sticky',
        // muiTableContainerProps: {
        //     sx: {
        //         maxHeight: '400px',
        //     },
        // },
    })

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={1}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div>
                <MaterialReactTable table={table}/>
            </div>
        )
    )
}
