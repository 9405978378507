export const isBossUser = (accessType) => {
    switch(accessType){
        case 0: //GOD
        case 1: //OWNER
            return true
        default:    //FIN
            return false
    }
}

export const isTechUser = (accessType) => {
    switch(accessType){
        case 0: //GOD
        case 1: //OWNER
        case 2: //ADMIN
        case 3: //TECH
            return true
        default:    //FIN
            return false
    }
}

export const accessName = (accessType) => {
    switch(accessType){
        case 0: return "GOD"
        case 1: return "OWNER"
        case 2: return "ADMIN"
        case 3: return "TECH"
        case 4: return "FINANCE"
        case 5: return "FRIEND1"
        default:
            return "UNKNOWN"
    }
}