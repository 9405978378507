import { React, Component, useState, useEffect, createContext, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import Swal from 'sweetalert2';

import { getAreasList, getDashboardShortDataByRange, getDashboardAreasStatusByRange, getTotalDeviceHistory } from '../../store/actions';
import {
    getCurrentUser,
    getIsAreasLoading
} from '../../store/selectors';
import moment from 'moment';
import AreaHashrateHistory from './AreaHH';
import AreaDevicesModal from './AreaDevicesModal';
import { accessName, isTechUser } from '../../utils/access';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { MaterialReactTable, showRowActionsColumn, useMaterialReactTable } from 'material-react-table';
import { Height } from '@mui/icons-material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const DeviceContext = createContext('');

export default function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const date_switch_list = [
        {title:'Вчера', is_active: true, sampling:'1h'},
        {title:'Неделя', is_active: false, sampling:'1d'},
        {title:'Месяц', is_active: false, sampling:'1d'}
    ]

    const currentUser = useSelector(state => getCurrentUser(state))
    const [_isAdminUser, setIsAdminUser] = useState(accessName(currentUser.access_type) == 'ADMIN')
    const [_isTechUser, setIsTechUser] = useState(accessName(currentUser.access_type) == 'TECH')
    const [_isFinanceUser, setIsFinanceUser] = useState(accessName(currentUser.access_type) == 'FINANCE')


    const [areas, setAreas] = useState([]);
    const isLoading = useSelector(state => getIsAreasLoading(state));

    const [dateList, setDateList] = useState(date_switch_list);
    const [dateIndex, setDateIndex] = useState(0);
    const [dashboardShortLoading, setDashboardShortLoading] = useState(true);
    const [dashboardShortData, setDashboardShortData] = useState({});

    const [areaStatusLoading, setAreaStatusLoading] = useState(true);
    const [areaStatusData, setAreaStatusData] = useState([]);

    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);

    const [isDeviceHistoryAppear, setIsDeviceHistoryAppear] = useState(false);
    const [isDeviceHistoryLoading, setIsDeviceHistoryLoading] = useState(false);
    const [selectedAreaUuid, setSelectedAreaUuid] = useState(0);
    const [totalDeviceHistory, setTotalDeviceHistory] = useState([]);
    const [totalDeviceChartData, setTotalDeviceChartData] = useState({
        labels: [],
        datasets: [
        ]
    });

    const fetchDashboardDataByRange = async (startDate, endDate) => {
        setDashboardShortLoading(true)
        setDashboardShortData({})

        const result = await dispatch(getDashboardShortDataByRange(startDate, endDate))
        setDashboardShortLoading(false)
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        setDashboardShortData(result.payload.data)
    }

    const fetchDashboardAreasStatusByRange = async (startDate, endDate) => {
        setAreaStatusLoading(true)
        setAreaStatusData([])

        const result = await dispatch(getDashboardAreasStatusByRange(startDate, endDate))
        setAreaStatusLoading(false)
        if(!result.success) {
            return Swal.fire({text:result.message, icon: 'error'});
        }

        setAreaStatusData(result.payload.data)
    }

    // useEffect( () => { 
    //     async function fetchData() {
    //         const result = await dispatch(getAreasList())
    //         if(!result.success) {
    //             return Swal.fire({text:result.message, icon: 'error'});
    //         }

    //         setAreas(result.payload.data)
    //     }
    //     fetchData();
    // }, []);

    const columnsTech = useMemo(()=>[
        {
            id: 'area_name',
            header: 'Объект',
            accessorFn: (row) => `${row['area_name']}`,
        },
        {
            id: 'room_count',
            header: 'Помещений',
            accessorFn: (row) => `${row['room_count']}`,
        },
        {
            id: 'device_count',
            header: 'Устройств',
            accessorFn: (row) => `${row['device_count']}`,
        },
        {
            id: 'device_count_necessary',
            header: 'Эталон устройств',
            accessorFn: (row) => `${row['device_count_necessary']}`,
        },
        {
            id: 'line_count',
            header: 'Линий',
            accessorFn: (row) => `${row['line_count']}`,
        },
        {
            id: 'place_count',
            header: 'Стелажей',
            accessorFn: (row) => `${row['place_count']}`,
        },
        {
            id: 'currencies',
            header: 'Валюты',
            accessorFn: (row) => `${row['currencies']?.join(', ')}`,
            Cell: ({ cell, row }) => {
                const _models = cell.getValue()
                return (
                    `${_models}`
                )
            },
        },
        {
            id: 'models',
            header: 'Модели',
            accessorFn: (row) => `${row['models']?.join(', ')}`,
            Cell: ({ cell, row }) => {
                const _models = cell.getValue()
                return (
                    `${_models}`
                )
            },
        },
        {
            id: 'hashrate_l_type',
            header: '(L type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_l_type']
                if(_hs == null) return `-`
                if(_hs > 10000) {
                    return `${(_hs/1000).toFixed(2)} Ph/s`
                }
                return `${(_hs)} Gh/s`
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'reference_hashrate_ltype',
            header: 'Эталон (L type)',
            accessorFn: (row) => `${row['reference_hashrate_ltype']} Gh/s`,
        },
        {
            id: 'hashrate_s_type',
            header: '(S type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_s_type']
                if(_hs == null) return `-`
                if(_hs > 10000) {
                    return `${(_hs/1000).toFixed(2)} Ph/s`
                }
                return `${(_hs)} Th/s`
            },
        },
        {
            id: 'reference_hashrate_stype',
            header: 'Эталон (S type)',
            accessorFn: (row) => `${row['reference_hashrate_stype']} Th/s`,
        },
        {
            id: 'hashrate_ks5_type',
            header: '(KS5 type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_ks5_type']
                if(_hs == null) return `-`
                return `${(_hs)}`
            },
        },
        {
            id: 'reference_hashrate_ks5type',
            header: 'Эталон (KS5 type)',
            accessorFn: (row) => `${row['reference_hashrate_ks5type']}`,
        },
        {
            id: 'warning_count',
            header: 'ПРОБЛЕМЫ',
            accessorFn: (row) => `${row['warning_count']}`,
        },
        {
            id: 'counter_count',
            header: 'СЧЕТЧИКОВ',
            accessorFn: (row) => `${row['counter_count']}`,
        },
    ])

    const columnsOwner = useMemo(()=>[
        {
            id: 'area_name',
            header: 'Объект',
            accessorFn: (row) => `${row['area_name']}`,
        },
        {
            id: 'room_count',
            header: 'Помещений',
            accessorFn: (row) => `${row['room_count']}`,
        },
        {
            id: 'device_count',
            header: 'Устройств',
            accessorFn: (row) => `${row['device_count']}`,
        },
        {
            id: 'device_count_necessary',
            header: 'Эталон устройств',
            accessorFn: (row) => `${row['device_count_necessary']}`,
        },
        {
            id: 'line_count',
            header: 'Линий',
            accessorFn: (row) => `${row['line_count']}`,
        },
        {
            id: 'place_count',
            header: 'Стелажей',
            accessorFn: (row) => `${row['place_count']}`,
        },
        {
            id: 'currencies',
            header: 'Валюты',
            accessorFn: (row) => `${row['currencies']?.join(', ')}`,
            Cell: ({ cell, row }) => {
                const _models = cell.getValue()
                return (
                    `${_models}`
                )
            },
        },
        {
            id: 'models',
            header: 'Модели',
            accessorFn: (row) => `${row['models']?.join(', ')}`,
            Cell: ({ cell, row }) => {
                const _models = cell.getValue()
                return (
                    `${_models}`
                )
            },
        },
        {
            id: 'income_ltc',
            header: 'Доход (LTC)',
            accessorFn: (row) => {
                const _data = (row?.income?.hasOwnProperty('LTC')) ? row['income']['LTC'] : 0
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'income_btc',
            header: 'Доход (BTC)',
            accessorFn: (row) => {
                const _data = (row?.income?.hasOwnProperty('BTC')) ? row['income']['BTC'] : 0
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'income_kas',
            header: 'Доход (KAS)',
            accessorFn: (row) => {
                const _data = (row?.income?.hasOwnProperty('KAS')) ? row['income']['KAS'] : 0
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'income_amount',
            header: 'Доход общий',
            accessorFn: (row) => {
                const _data = row['income_amount']
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'total_consumption_amount',
            header: 'Расход',
            accessorFn: (row) => {
                const _data = row['total_consumption_amount']
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'profit_amount',
            header: 'Прибыль',
            accessorFn: (row) => {
                const _data = row['profit_amount']
                return toCurrency(_data, 'RUB', 'Ru-ru')
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'hashrate_l_type',
            header: '(L type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_l_type']
                if(_hs == null) return `-`
                if(_hs > 10000) {
                    return `${(_hs/1000).toFixed(2)} Ph/s`
                }
                return `${(_hs)} Gh/s`
            },
            Cell: ({ cell, row }) => {
                const _data = cell.getValue()
                return (
                    `${_data}`
                )
            },
        },
        {
            id: 'reference_hashrate_ltype',
            header: 'Эталон (L type)',
            accessorFn: (row) => `${row['reference_hashrate_ltype']} Gh/s`,
        },
        {
            id: 'hashrate_s_type',
            header: '(S type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_s_type']
                if(_hs == null) return `-`
                if(_hs > 10000) {
                    return `${(_hs/1000).toFixed(2)} Ph/s`
                }
                return `${(_hs)} Th/s`
            },
        },
        {
            id: 'reference_hashrate_stype',
            header: 'Эталон (S type)',
            accessorFn: (row) => `${row['reference_hashrate_stype']} Th/s`,
        },
        {
            id: 'hashrate_ks5_type',
            header: '(KS5 type)',
            accessorFn: (row) => {
                const _hs = row['hashrate_ks5_type']
                if(_hs == null) return `-`
                return `${(_hs)}`
            },
        },
        {
            id: 'reference_hashrate_ks5type',
            header: 'Эталон (KS5 type)',
            accessorFn: (row) => `${row['reference_hashrate_ks5type']}`,
        },
        {
            id: 'warning_count',
            header: 'Проблемы',
            accessorFn: (row) => `${row['warning_count']}`,
        },
        {
            id: 'counter_count',
            header: 'Счетчиков',
            accessorFn: (row) => `${row['counter_count']}`,
            showRowActionsColumn: false
        },
    ])

    const table = useMaterialReactTable({
        columns: (_isTechUser || _isAdminUser) ? columnsTech : columnsOwner,
        data: areaStatusData,
        state: {
            isLoading: areaStatusLoading,
            showProgressBars: areaStatusLoading,
            density: 'compact',
            showColumnFilters: false,
        },
        enableColumnFilters: false,
        enableColumnActions: false,
        enablePagination: false,
        enableRowNumbers: false,
        enableSorting: false,
        enableDensityToggle: true,
        enableColumnResizing: true,
        layoutMode:'semantic',
        enableStickyHeader: true,
        muiTableContainerProps: {
            sx: { 
                height: 'calc(100vh - 500px)',
                overflow: 'auto'
            },
        }
    })

    useEffect( () => {
        var now = new Date();

        let startDate;
        let endDate;
        switch(dateIndex){
            case 0:
                // весь день вчера
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            case 1:
                // 7 дней до всего вчера
                startDate = moment(now).subtract(7, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            case 2:
                //30 дней до всего вчера
                startDate = moment(now).subtract(30, 'days')
                endDate = moment(now) //.subtract(1, 'days')
                break
            default:
                startDate = moment(now).subtract(1, 'days')
                endDate = moment(now) //.subtract(1, 'days')
        }
        
        var startDateString = startDate.format('YYYY-MM-DDT00:00:00');
        var endDateString = endDate.format('YYYY-MM-DDT00:00:00');
        //var endDateString = endDate.format('YYYY-MM-DDT23:59:59');

        fetchDashboardDataByRange(startDateString, endDateString)
        fetchDashboardAreasStatusByRange(startDateString, endDateString)
    }, [dateIndex]);

    const onSwitchStage = async (index) => {
        setDateList([
            {title:'Вчера', is_active: (index == 0), sampling:'1h'},
            {title:'Неделя', is_active: (index == 1), sampling:'1d'},
            {title:'Месяц', is_active: (index == 2), sampling:'1d'}
        ])
        setDateIndex(index)
    }

    const toCurrency = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, { style: 'currency', currency: curr }).format(n);

    //Необходимо выводить суммарное значение всех асиков в Террахешах если меньше 10 000 террахеш, если больше то выводим в петтахеш и округляем до 2 знаков после запятой.
    const transformHashrate = (hashrate) => {
        if(hashrate > 10000) {
            return [hashrate/1000, 'ph/s']
        }

        return [hashrate, 'th/s']
    }

    const onShowDeviceHistory = async (area_id, range_T='1h') => {
        setIsDeviceHistoryAppear(true)
        setIsDeviceHistoryLoading(true)
        
        if(area_id != 0)    {
            setSelectedAreaUuid(area_id)
        }

        const result = await dispatch(getTotalDeviceHistory(((area_id == 0) ? selectedAreaUuid : area_id), range_T))
        if(!result.success) {
            setIsDeviceHistoryLoading(false)
            setIsDeviceHistoryAppear(false)
            return Swal.fire({text:result.message, icon: 'error'});
        }

        let x = []
        let y = []
        result.payload.data.map((obj) => {
            x.push(new Date(Date.parse(obj.time)).toLocaleString())
            y.push(obj.count)
        })

        setTotalDeviceChartData({
            labels:x,
            datasets: [
                {
                  label: 'Device count',
                  data: y,
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
            ],
            options: {
                maintainAspectRatio: false,
            },
            range_t:range_T
        })

        setTotalDeviceHistory(result.payload.data)
        setIsDeviceHistoryLoading(false)
    }

    const onHideDeviceHistory = async () => {
        setIsDeviceHistoryLoading(false)
        setIsDeviceHistoryAppear(false)
        setSelectedAreaUuid(0)
    }

    return (
        (isLoading) ? (
            <div className="hero is-fullheight is-flex">
                <div className="container has-text-centered mt-4"> 
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                    />
                        <p className="mt-2">Загрузка</p>
                </div>
            </div>
        ):(
            <div className="column is-full">
                {
                    (_isTechUser || _isAdminUser) ? (
                        null
                    ): (
                        <div className="columns is-multiline is-centered">
                            <div className='column is-full'>
                                <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'40px'}}>
                                    <nav className="breadcrumb has-bullet-separator is-centered pt-2" aria-label="breadcrumbs">
                                        <ul>
                                            {
                                                dateList.map((item, index) => {
                                                    return (
                                                        <li key={index} className={(item.is_active) ? "is-active": ""}>
                                                            <a href="#" onClick={()=>{onSwitchStage(index)}}>{item.title}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    (dashboardShortLoading) ? (
                        <div className='hero is-flex is-centered'>
                            <div className="container has-text-centered mt-4"> 
                                <Oval
                                    height={40}
                                    width={40}
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#4fa94d"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}/>
                            </div>
                        </div>
                    ) : (
                        (_isTechUser || _isAdminUser) ? (
                            null
                        ):(
                            (dashboardShortData.hasOwnProperty('income_today_rub'))?(
                                <nav className="level">
                                    <div className="level-item has-text-centered">
                                        <div>
                                            <p className="heading">доход за период</p>
                                            <p className="title">+ {toCurrency(dashboardShortData['income_today_rub'], 'RUB', 'Ru-ru')}</p>
                                            <p className="subtitle mt-1">+ {toCurrency(dashboardShortData['income_today_usd'], 'USD', 'en-us')}</p>
                                        </div>
                                    </div>
                                    <div className="level-item has-text-centered">
                                        <div>
                                            <p className="heading">расход за период</p>
                                            <p className="title">- {toCurrency(dashboardShortData['outcome_today_rub'], 'RUB', 'Ru-ru')}</p>
                                            <p className="subtitle mt-1">- {toCurrency(dashboardShortData['outcome_today_usd'], 'USD', 'en-us')}</p>
                                        </div>
                                    </div>
                                    <div className="level-item has-text-centered">
                                        <div>
                                            <p className="heading">прибыль за период</p>
                                            <p className="title">{toCurrency(dashboardShortData['profit_today_rub'], 'RUB', 'Ru-ru')}</p>
                                            <p className="subtitle mt-1">{toCurrency(dashboardShortData['profit_today_usd'], 'USD', 'en-us')}</p>
                                        </div>
                                    </div>
                                </nav>
                            ) : (
                                <div className='hero is-flex is-centered'>
                                    <div className="container has-text-centered mt-4"> 
                                        <>Нет данных</>
                                    </div>
                                </div>
                            )
                        )
                    )
                }
                {/* {
                    (!dashboardShortLoading) ? (
                        <div className="columns is-multiline is-centered">
                            <div className='column is-full'>
                                <div className="card mt-4 mx-4 is-vcentered mt-1" style={{height:'20px'}}>
                            
                                </div>
                            </div>
                        </div>
                    ) : (null)
                } */}
                <MaterialReactTable table={table}/>
                
                <DeviceContext.Provider value={{selectedDevice, setSelectedDevice}}>
                    <AreaHashrateHistory data={{}} onFinish={()=>{}}/>
                </DeviceContext.Provider>

                <DeviceContext.Provider value={{selectedArea, setSelectedArea}}>
                    <AreaDevicesModal data={{}} onFinish={()=>{}}/>
                </DeviceContext.Provider>

                <div className={isDeviceHistoryAppear ? "modal is-active" : "modal"}>
                    <div className="modal-background" onClick={onHideDeviceHistory}></div>
                        <div className="modal-content" style={{backgroundColor:'white', height:'60%', width:'80%'}}>
                            <div style={{backgroundColor:'white', width:'100%', height:'100%'}}>
                                {(isDeviceHistoryLoading)? (
                                    <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center" style={{backgroundColor:'transparent', height:'100%'}}>
                                        <Oval
                                            height={80}
                                            width={80}
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor="#4fa94d"
                                            strokeWidth={2}
                                            strokeWidthSecondary={2}
                                        />
                                    </div>
                                ): (
                                    <div style={{backgroundColor:'white', width:'100%', height:'100%'}}>
                                        <div className="tabs is-centered">
                                            <ul>
                                                <li className={(totalDeviceChartData.range_t == '1h')?'is-active':''}><a onClick={()=>{onShowDeviceHistory(0, '1h')}}>Час</a></li>
                                                <li className={(totalDeviceChartData.range_t == '1d')?'is-active':''}><a onClick={()=>{onShowDeviceHistory(0, '1d')}}>День</a></li>
                                                <li className={(totalDeviceChartData.range_t == '1w')?'is-active':''}><a onClick={()=>{onShowDeviceHistory(0, '1w')}}>Неделя</a></li>
                                                <li className={(totalDeviceChartData.range_t == '1M')?'is-active':''}><a onClick={()=>{onShowDeviceHistory(0, '1M')}}>Месяц</a></li>
                                            </ul>
                                        </div>
                                        <div style={{width:'100%', height:'90%'}}>
                                            <Line options={
                                                {
                                                    responsive: true,
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        legend: {
                                                            position: 'top',
                                                        },
                                                        title: {
                                                            display: false,
                                                            text: 'Device count',
                                                        }
                                                    },
                                                }
                                            } data={totalDeviceChartData}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button className="modal-close is-large" aria-label="close" onClick={onHideDeviceHistory}></button>
                    </div>
                </div>
        )
    );
}
